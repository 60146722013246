<template>
    <div class="Band" id="wrapper">
        <NavSection basePath="/wolfson" />

        <!-- HeaderSection Begin -->
        <section class="Band-content u-padTop1 u-padBottom8" @click="resetHeaderVisibility">
            <div class="u-padSides8">
                <div class="u-containSpread u-textCenter">
                    <svg viewBox="0 0 657.81 272.06" class="wolfson-header u-textWhite" role="presentation">
                        <path
                            d="m338.45,66.91c-4.69,0-6.36-3.17-3.71-7.05L373.65,3.15c2.66-3.87,6.95-3.83,9.54.08l37.5,56.56c2.6,3.91.88,7.12-3.82,7.12h-78.42Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m414.17,153.38c4.37,0,7.95-3.57,7.95-7.95v-63.5c0-4.38-3.58-7.95-7.95-7.95h-72.96c-4.37,0-7.96,3.57-7.96,7.95v63.5c0,4.37,3.58,7.95,7.96,7.95h72.96Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m422.13,81.93c0-4.38-3.58-7.95-7.95-7.95h-72.96c-4.37,0-7.96,3.57-7.96,7.95v63.5c71.93-13.19,88.86-63.5,88.86-63.5Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m414.17,153.38c4.37,0,7.95-3.57,7.95-7.95v-63.5c0-4.38-3.58-7.95-7.95-7.95h-72.96c-4.37,0-7.96,3.57-7.96,7.95v63.5c0,4.37,3.58,7.95,7.96,7.95h72.96Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m530.9,66.91c-4.69,0-6.36-3.17-3.7-7.05l38.88-56.72c2.67-3.87,6.96-3.83,9.56.08l37.5,56.56c2.59,3.91.88,7.12-3.82,7.12h-78.42Z"
                            fill="#fff" stroke-width="0" />
                        <path d="m538.72,43.4l27.08-39.77c3.27-5.39,7.52-4.47,10.6.71" fill="#fff" opacity=".65"
                            stroke-width="0" />
                        <path
                            d="m606.62,153.38c4.38,0,7.95-3.57,7.95-7.95v-63.5c0-4.38-3.56-7.95-7.95-7.95h-72.96c-4.37,0-7.95,3.57-7.95,7.95v63.5c0,4.37,3.58,7.95,7.95,7.95h72.96Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m434.68,66.91c-4.7,0-6.36-3.17-3.71-7.05L469.87,3.15c2.66-3.87,6.95-3.83,9.55.08l37.5,56.56c2.59,3.91.88,7.12-3.83,7.12h-78.41Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m510.4,153.38c4.37,0,7.95-3.57,7.95-7.95v-63.5c0-4.38-3.58-7.95-7.95-7.95h-72.96c-4.38,0-7.95,3.57-7.95,7.95v63.5c0,4.37,3.56,7.95,7.95,7.95h72.96Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m25.29,130.56h.17l12.4-43.32h7.19l12.89,43.32h.17l12.23-42.49h12.73l-21.08,63.56h-8.93l-12.39-40.74h-.17l-11.74,40.74h-8.93L0,88.07h12.73l12.56,42.49Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m120.26,130.14c0,12.56-8.6,21.58-21.33,21.58s-21.32-9.02-21.32-21.58,8.68-21.66,21.32-21.66,21.33,9.01,21.33,21.66Zm-30.91,0c0,5.46,3.63,11.33,9.58,11.33s9.59-5.87,9.59-11.33-3.63-11.41-9.59-11.41-9.58,5.79-9.58,11.41Z"
                            fill="#fff" stroke-width="0" />
                        <polyline points="138.12 80.29 138.12 150.39 126.54 150.39 126.54 80.29" fill="#fff"
                            stroke-width="0" />
                        <path
                            d="m169.58,80.63v10.33c-1.49-.75-2.98-1.41-4.71-1.41-5.13,0-4.55,6.71-4.55,10.01v10.25h9.26v9.75h-9.26v30.84h-11.56v-30.84h-3.89v-9.75h3.89v-11.91c0-9.92,4.13-18.6,15.29-18.6"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m195.04,119.39c-1.65-1.57-3.8-2.81-6.12-2.81-1.9,0-3.8,1.49-3.8,3.47,0,2.99,4.22,3.8,8.35,5.62,4.22,1.82,8.35,4.63,8.35,11.65,0,9.02-7.28,14.39-15.79,14.39-5.44,0-10.74-2.48-14.29-6.78l5.29-6.86c2.24,2.47,5.21,4.71,8.6,4.71,2.56,0,4.63-1.74,4.63-4.38,0-3.55-4.06-4.37-8.09-6.12-4.06-1.73-8.11-4.13-8.11-10.67,0-7.6,5.3-13.14,12.9-13.14,4.3,0,9.75,1.33,13.05,4.21l-4.96,6.7Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m248.65,130.14c0,12.56-8.6,21.58-21.33,21.58s-21.32-9.02-21.32-21.58,8.68-21.66,21.32-21.66,21.33,9.01,21.33,21.66Zm-30.91,0c0,5.46,3.63,11.33,9.58,11.33s9.59-5.87,9.59-11.33-3.64-11.41-9.59-11.41-9.58,5.79-9.58,11.41Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m266.38,113.52h.16c2.4-3.63,6.45-5.04,10.58-5.04,10.91,0,14.05,7.68,14.05,17.11v24.8h-11.57v-21.5c0-4.88.25-11.15-6.28-11.15-6.86,0-6.95,7.68-6.95,12.56v20.09h-11.58v-40.58h11.58v3.72Z"
                            fill="#fff" stroke-width="0" />
                        <path d="m313.95,188.48l-11.9,24.81-7.69-.91,9.01-25.13,10.58,1.24Z" fill="#fff"
                            stroke-width="0" />
                        <path
                            d="m44.65,205.43c-3.39-4.14-8.51-6.62-13.71-6.62-11.66,0-18.35,10.74-18.35,21.49s6.94,21,18.26,21c5.21,0,10.42-2.73,13.8-6.61v14.46c-4.54,2.15-9.09,3.73-14.13,3.73C12.99,252.88.02,237.75.02,220.55s12.48-33.31,30.75-33.31c4.87,0,9.67,1.33,13.88,3.64v14.55Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m63.92,181.13v32.89h.16c2.4-3.3,6.45-4.71,10.58-4.71,10.91,0,14.05,7.68,14.05,17.11v24.8h-11.57v-21.5c0-4.87.25-11.15-6.28-11.15-6.86,0-6.94,7.68-6.94,12.56v20.09h-11.58v-70.09h11.58Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m110.22,197.1c0,3.81-3.06,6.87-6.86,6.87s-6.87-3.06-6.87-6.87,3.06-6.86,6.87-6.86,6.86,3.14,6.86,6.86Zm-1.08,54.12h-11.57v-40.58h11.57v40.58Z"
                            fill="#fff" stroke-width="0" />
                        <path d="m129.61,251.22h-11.57v-70.09h11.57v70.09Z" fill="#fff" stroke-width="0" />
                        <path
                            d="m166.1,247.42h-.16c-2.4,3.3-6.71,5.12-10.92,5.12-11.9,0-18.68-10.83-18.68-21.83s6.86-21.4,18.52-21.4c4.21,0,8.52,1.81,11.08,5.04h.16v-33.23h11.57v70.09h-11.57v-3.8Zm1.15-16.53c0-5.46-3.63-11.33-9.58-11.33s-9.58,5.88-9.58,11.33,3.63,11.41,9.58,11.41,9.58-5.96,9.58-11.41Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m198.35,214.85h.16c2.32-3.55,5.78-5.54,10.08-5.54,1.65,0,3.3.41,4.79,1l-1.4,10.74c-1.41-1-3.06-1.5-4.79-1.5-8.02,0-8.84,7.11-8.84,13.4v18.27h-11.58v-40.58h11.58v4.21Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m225.4,232.79c0,5.13,1.99,10.9,8.02,10.9,3.8,0,6.2-2.31,7.77-5.54l10.67,1.74c-2.81,8.11-9.92,12.65-18.44,12.65-12.57,0-20.25-8.93-20.25-21.25s6.53-21.98,19.68-21.98,19.25,9.84,19.25,22.07v1.41h-26.7Zm15.79-7.19c-.57-4.13-3.21-8.18-7.68-8.18s-7.19,3.97-7.77,8.18h15.45Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m269.76,214.36h.16c2.4-3.64,6.45-5.04,10.58-5.04,10.91,0,14.05,7.68,14.05,17.11v24.8h-11.57v-21.5c0-4.87.25-11.15-6.28-11.15-6.86,0-6.94,7.68-6.94,12.56v20.09h-11.58v-40.58h11.58v3.72Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m328.74,220.22c-1.65-1.57-3.81-2.81-6.12-2.81-1.9,0-3.81,1.5-3.81,3.47,0,2.98,4.22,3.8,8.36,5.62,4.21,1.82,8.34,4.63,8.34,11.65,0,9.01-7.27,14.39-15.79,14.39-5.44,0-10.74-2.49-14.29-6.78l5.3-6.86c2.23,2.47,5.19,4.7,8.59,4.7,2.56,0,4.62-1.73,4.62-4.37,0-3.56-4.05-4.38-8.09-6.12-4.05-1.74-8.11-4.13-8.11-10.67,0-7.6,5.3-13.14,12.9-13.14,4.3,0,9.76,1.32,13.06,4.21l-4.96,6.69Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m406.79,212.62v-24.55h12.15v62.33h-12.15v-27.85h-22.48v27.85h-12.15v-62.33h12.15v24.55h22.48Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m468.09,230.15c0,12.57-8.59,21.57-21.32,21.57s-21.32-9-21.32-21.57,8.68-21.66,21.32-21.66,21.32,9.02,21.32,21.66Zm-30.91,0c0,5.46,3.64,11.33,9.58,11.33s9.59-5.87,9.59-11.33-3.63-11.41-9.59-11.41-9.58,5.79-9.58,11.41Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m494.38,219.4c-1.65-1.56-3.8-2.8-6.12-2.8-1.9,0-3.8,1.48-3.8,3.46,0,2.98,4.22,3.81,8.35,5.62,4.22,1.82,8.36,4.63,8.36,11.65,0,9.02-7.27,14.38-15.79,14.38-5.45,0-10.74-2.48-14.29-6.78l5.29-6.85c2.23,2.47,5.2,4.7,8.59,4.7,2.56,0,4.63-1.73,4.63-4.37,0-3.56-4.05-4.38-8.09-6.12-4.05-1.73-8.11-4.13-8.11-10.67,0-7.6,5.29-13.15,12.9-13.15,4.29,0,9.75,1.33,13.05,4.23l-4.96,6.69Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m519.02,213.54c2.65-3.14,6.95-5.05,11.25-5.05,11.49,0,18.51,10.75,18.51,21.42s-6.77,21.82-18.76,21.82c-4.13,0-8.51-1.82-10.82-5.12h-.17v25.46h-11.58v-62.25h11.58v3.73Zm18.02,16.53c0-5.46-3.64-11.33-9.59-11.33s-9.58,5.87-9.58,11.33,3.64,11.41,9.58,11.41,9.59-5.95,9.59-11.41Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m568.01,196.29c0,3.8-3.06,6.86-6.87,6.86s-6.87-3.06-6.87-6.86,3.06-6.87,6.87-6.87,6.87,3.15,6.87,6.87Zm-1.08,54.11h-11.57v-40.59h11.57v40.59Z"
                            fill="#fff" stroke-width="0" />
                        <path d="m588.67,250.4h-11.58v-30.84h-3.96v-9.76h3.96v-13.71h11.58v13.71h7.11v9.76h-7.11v30.84Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m637.4,250.4h-11.4v-3.72h-.17c-2.47,3.22-6.77,5.04-10.99,5.04-11.9,0-18.69-10.83-18.69-21.82s6.86-21.42,18.52-21.42c4.2,0,8.51,1.83,11.32,5.14v-3.81h11.4v40.59Zm-10.25-20.33c0-5.46-3.72-11.33-9.67-11.33s-9.58,5.87-9.58,11.33,3.64,11.41,9.58,11.41,9.67-5.95,9.67-11.41Z"
                            fill="#fff" stroke-width="0" />
                        <path d="m657.81,250.4h-11.58v-70.1h11.58v70.1Z" fill="#fff" stroke-width="0" />
                    </svg>
                    <span class="u-hiddenVisually">Wolfson Children's Hospital</span>
                </div>
            </div>
        </section>

        <section class="Band-content u-textWhite" v-if="isHeaderVisible">
            <div class="u-padSides8">
                <div class="u-containSpread">
                    <h2 class="missionQuote u-textCenter u-textSerif">
                        Providing optimal health for the region's children by providing patient- and family-centered
                        care, education, research and child advocacy.
                    </h2>
                </div>
            </div>
        </section>

        <section class="Band-content u-bgWhite u-padTop8 u-textBlack" v-if="isHeaderVisible">
            <div class="u-padSides8">
                <div class="u-containSpread">
                    <div>
                        <h3 class="intro">
                            Recognized among America's Best Children's Hospitals by U.S. News & World Report year after
                            year, Wolfson Children's Hospital of Jacksonville, Florida is the only full-service tertiary
                            hospital for children in the region, serving North Florida, South Georgia and beyond.
                        </h3>
                        <h3 class="intro">
                            We are a non-profit organization that provides care for all children, regardless of their
                            ability to pay.
                        </h3>
                    </div>
                </div>
            </div>
        </section>
        <!-- HeaderSection End -->

        <router-view></router-view>

        <!-- FooterSection Begin -->
        <QuestionSection />
        <section class="Band-content u-bgWhite">
            <div class="u-padSides8">
                <div class="u-containSpread u-textCenter">

                    <div class="Grid Grid--withGap u-textCenter u-padTop2 u-spaceBottom9">
                        <div class="Grid-cell u-sizefull u-padTop9">
                            <div class="u-textBlackAlt">
                                <div class="u-containSpread u-textCenter u-spaceBottom8">
                                    <svg viewBox="0 0 657.8 272.05" class="wolfson-footer" role="presentation">
                                        <path
                                            d="M338.5,66.95c-4.7,0-6.4-3.2-3.7-7L373.7,3.25c2.7-3.9,7-3.8,9.5.1l37.5,56.6c2.6,3.9.9,7.1-3.8,7.1h-78.4v-.1Z"
                                            fill="#00aeef" stroke-width="0" />
                                        <path
                                            d="M414.2,153.45c4.4,0,8-3.6,8-7.9v-63.6c0-4.4-3.6-7.9-8-7.9h-73c-4.4,0-8,3.6-8,7.9v63.5c0,4.4,3.6,7.9,8,7.9h73v.1Z"
                                            fill="#fff" stroke-width="0" />
                                        <path
                                            d="M422.1,81.95c0-4.4-3.6-7.9-8-7.9h-73c-4.4,0-8,3.6-8,7.9v63.5c72.1-13.2,89-63.5,89-63.5h0Z"
                                            fill="#fff" stroke-width="0" />
                                        <path
                                            d="M414.2,153.45c4.4,0,8-3.6,8-7.9v-63.6c0-4.4-3.6-7.9-8-7.9h-73c-4.4,0-8,3.6-8,7.9v63.5c0,4.4,3.6,7.9,8,7.9h73v.1Z"
                                            fill="#e31837" stroke-width="0" />
                                        <path
                                            d="M530.9,66.95c-4.7,0-6.4-3.2-3.7-7l38.9-56.7c2.7-3.9,7-3.8,9.6.1l37.5,56.6c2.6,3.9.9,7.1-3.8,7.1l-78.5-.1h0Z"
                                            fill="#00aeef" stroke-width="0" />
                                        <path d="M538.7,43.45l27.1-39.8c3.3-5.4,7.5-4.5,10.6.7" fill="#00aeef"
                                            stroke-width="0" />
                                        <path
                                            d="M606.6,153.45c4.4,0,8-3.6,8-7.9v-63.6c0-4.4-3.6-7.9-8-7.9h-73c-4.4,0-8,3.6-8,7.9v63.5c0,4.4,3.6,7.9,8,7.9h73v.1Z"
                                            fill="#00907e" stroke-width="0" />
                                        <path
                                            d="M434.7,66.95c-4.7,0-6.4-3.2-3.7-7L469.9,3.25c2.7-3.9,7-3.8,9.5.1l37.5,56.6c2.6,3.9.9,7.1-3.8,7.1h-78.4v-.1Z"
                                            fill="#00aeef" stroke-width="0" />
                                        <path
                                            d="M510.4,153.45c4.4,0,7.9-3.6,7.9-7.9v-63.6c0-4.4-3.6-7.9-8-7.9h-73c-4.4,0-8,3.6-8,7.9v63.5c0,4.4,3.6,7.9,8,7.9h73.1v.1Z"
                                            fill="#ffd65a" stroke-width="0" />
                                        <path
                                            d="M25.3,130.65h.2l12.4-43.3h7.1l12.9,43.3h.2l12.2-42.5h12.7l-21,63.5h-8.9l-12.4-40.7h-.2l-11.7,40.7h-8.9L0,88.15h12.7l12.6,42.5Z"
                                            fill="#000000" stroke-width="0" />
                                        <path
                                            d="M120.3,130.15c0,12.6-8.6,21.6-21.3,21.6s-21.3-9-21.3-21.6,8.7-21.7,21.3-21.7,21.3,9.1,21.3,21.7ZM89.4,130.15c0,5.5,3.6,11.3,9.6,11.3s9.6-5.9,9.6-11.3-3.6-11.4-9.6-11.4-9.6,5.8-9.6,11.4Z"
                                            fill="#000000" stroke-width="0" />
                                        <polyline points="138.1 80.35 138.1 150.45 126.5 150.45 126.5 80.35"
                                            fill="#000000" stroke-width="0" />
                                        <path
                                            d="M169.6,80.65v10.4c-1.5-.8-3-1.4-4.7-1.4-5.1,0-4.6,6.7-4.6,10v10.2h9.3v9.8h-9.3v30.8h-11.6v-30.8h-3.9v-9.8h3.9v-11.9c0-9.9,4.1-18.6,15.3-18.6"
                                            fill="#000000" stroke-width="0" />
                                        <path
                                            d="M195,119.45c-1.6-1.6-3.8-2.8-6.1-2.8-1.9,0-3.8,1.5-3.8,3.5,0,3,4.2,3.8,8.4,5.6,4.2,1.8,8.4,4.6,8.4,11.7,0,9-7.3,14.4-15.8,14.4-5.4,0-10.7-2.5-14.3-6.8l5.3-6.9c2.2,2.5,5.2,4.7,8.6,4.7,2.6,0,4.6-1.7,4.6-4.4,0-3.6-4.1-4.4-8.1-6.1-4.1-1.7-8.1-4.1-8.1-10.7,0-7.6,5.3-13.1,12.9-13.1,4.3,0,9.8,1.3,13.1,4.2l-5.1,6.7h0Z"
                                            fill="#000000" stroke-width="0" />
                                        <path
                                            d="M248.6,130.15c0,12.6-8.6,21.6-21.3,21.6s-21.3-9-21.3-21.6,8.7-21.7,21.3-21.7,21.4,9.1,21.3,21.7h0ZM217.7,130.15c0,5.5,3.6,11.3,9.6,11.3s9.6-5.9,9.6-11.3-3.6-11.4-9.6-11.4-9.6,5.8-9.6,11.4Z"
                                            fill="#000000" stroke-width="0" />
                                        <path
                                            d="M266.4,113.55h.2c2.4-3.6,6.5-5,10.6-5,10.9,0,14,7.7,14,17.1v24.8h-11.6v-21.5c0-4.9.2-11.2-6.3-11.2-6.9,0-7,7.7-7,12.6v20.1h-11.6v-40.6h11.6l.1,3.7h0Z"
                                            fill="#000000" stroke-width="0" />
                                        <path d="M314,188.55l-11.9,24.8-7.7-.9,9-25.1,10.6,1.2h0Z" fill="#000000"
                                            stroke-width="0" />
                                        <path
                                            d="M44.7,205.45c-3.4-4.1-8.5-6.6-13.7-6.6-11.7,0-18.4,10.7-18.4,21.5s6.9,21,18.3,21c5.2,0,10.4-2.7,13.8-6.6v14.5c-4.5,2.1-9.1,3.7-14.1,3.7-17.6,0-30.6-15.1-30.6-32.3s12.5-33.3,30.8-33.3c4.9,0,9.7,1.3,13.9,3.6v14.5h0Z"
                                            fill="#000000" stroke-width="0" />
                                        <path
                                            d="M63.9,181.15v32.9h.2c2.4-3.3,6.4-4.7,10.6-4.7,10.9,0,14.1,7.7,14.1,17.1v24.8h-11.7v-21.5c0-4.9.2-11.1-6.3-11.1-6.9,0-6.9,7.7-6.9,12.6v20.1h-11.6v-70.1h11.6v-.1Z"
                                            fill="#000000" stroke-width="0" />
                                        <path
                                            d="M110.2,197.15c0,3.8-3.1,6.9-6.9,6.9s-6.9-3.1-6.9-6.9,3.1-6.9,6.9-6.9,6.9,3.2,6.9,6.9ZM109.1,251.25h-11.5v-40.6h11.6v40.6h-.1Z"
                                            fill="#000000" stroke-width="0" />
                                        <path d="M129.6,251.25h-11.6v-70.1h11.6v70.1Z" fill="#000000"
                                            stroke-width="0" />
                                        <path
                                            d="M166.1,247.45h-.2c-2.4,3.3-6.7,5.1-10.9,5.1-11.9,0-18.7-10.8-18.7-21.8s6.9-21.4,18.5-21.4c4.2,0,8.5,1.8,11.1,5h.2v-33.2h11.6v70.1h-11.6v-3.8h0ZM167.2,230.95c0-5.5-3.6-11.3-9.6-11.3s-9.6,5.9-9.6,11.3,3.6,11.4,9.6,11.4,9.6-6,9.6-11.4Z"
                                            fill="#000000" stroke-width="0" />
                                        <path
                                            d="M198.4,214.95h.2c2.3-3.6,5.8-5.5,10.1-5.5,1.6,0,3.3.4,4.8,1l-1.4,10.7c-1.4-1-3.1-1.5-4.8-1.5-8,0-8.8,7.1-8.8,13.4v18.3h-11.6v-40.6h11.6l-.1,4.2h0Z"
                                            fill="#000000" stroke-width="0" />
                                        <path
                                            d="M225.4,232.85c0,5.1,2,10.9,8,10.9,3.8,0,6.2-2.3,7.8-5.5l10.7,1.7c-2.8,8.1-9.9,12.6-18.4,12.6-12.6,0-20.2-8.9-20.2-21.2s6.5-22,19.7-22,19.2,9.8,19.2,22.1v1.4h-26.8ZM241.2,225.65c-.6-4.1-3.2-8.2-7.7-8.2s-7.2,4-7.8,8.2h15.5Z"
                                            fill="#000000" stroke-width="0" />
                                        <path
                                            d="M269.8,214.45h.2c2.4-3.6,6.5-5,10.6-5,10.9,0,14,7.7,14,17.1v24.8h-11.6v-21.5c0-4.9.2-11.1-6.3-11.1-6.9,0-6.9,7.7-6.9,12.6v20.1h-11.6v-40.6h11.6v3.6h0Z"
                                            fill="#000000" stroke-width="0" />
                                        <path
                                            d="M328.7,220.25c-1.6-1.6-3.8-2.8-6.1-2.8-1.9,0-3.8,1.5-3.8,3.5,0,3,4.2,3.8,8.4,5.6,4.2,1.8,8.3,4.6,8.3,11.6,0,9-7.3,14.4-15.8,14.4-5.4,0-10.7-2.5-14.3-6.8l5.3-6.9c2.2,2.5,5.2,4.7,8.6,4.7,2.6,0,4.6-1.7,4.6-4.4,0-3.6-4-4.4-8.1-6.1-4-1.7-8.1-4.1-8.1-10.7,0-7.6,5.3-13.1,12.9-13.1,4.3,0,9.8,1.3,13.1,4.2l-5,6.8h0Z"
                                            fill="#000000" stroke-width="0" />
                                        <path
                                            d="M406.8,212.65v-24.6h12.1v62.3h-12.1v-27.9h-22.5v27.9h-12.1v-62.3h12.1v24.6h22.5Z"
                                            fill="#000000" stroke-width="0" />
                                        <path
                                            d="M468.1,230.15c0,12.6-8.6,21.6-21.3,21.6s-21.3-9-21.3-21.6,8.7-21.7,21.3-21.7,21.3,9.1,21.3,21.7ZM437.2,230.15c0,5.5,3.6,11.3,9.6,11.3s9.6-5.9,9.6-11.3-3.6-11.4-9.6-11.4-9.6,5.8-9.6,11.4Z"
                                            fill="#000000" stroke-width="0" />
                                        <path
                                            d="M494.4,219.45c-1.6-1.6-3.8-2.8-6.1-2.8-1.9,0-3.8,1.5-3.8,3.5,0,3,4.2,3.8,8.4,5.6,4.2,1.8,8.4,4.6,8.4,11.6,0,9-7.3,14.4-15.8,14.4-5.5,0-10.7-2.5-14.3-6.8l5.3-6.9c2.2,2.5,5.2,4.7,8.6,4.7,2.6,0,4.6-1.7,4.6-4.4,0-3.6-4-4.4-8.1-6.1-4-1.7-8.1-4.1-8.1-10.7,0-7.6,5.3-13.1,12.9-13.1,4.3,0,9.8,1.3,13,4.2l-5,6.8h0Z"
                                            fill="#000000" stroke-width="0" />
                                        <path
                                            d="M519,213.55c2.7-3.1,7-5.1,11.2-5.1,11.5,0,18.5,10.8,18.5,21.4s-6.8,21.8-18.8,21.8c-4.1,0-8.5-1.8-10.8-5.1h-.1v25.5h-11.6v-62.2h11.6v3.7h0ZM537,230.15c0-5.5-3.6-11.3-9.6-11.3s-9.6,5.9-9.6,11.3,3.6,11.4,9.6,11.4,9.6-6,9.6-11.4Z"
                                            fill="#000000" stroke-width="0" />
                                        <path
                                            d="M568,196.35c0,3.8-3.1,6.9-6.9,6.9s-6.9-3.1-6.9-6.9,3.1-6.9,6.9-6.9,6.9,3.2,6.9,6.9ZM566.9,250.45h-11.6v-40.6h11.6v40.6Z"
                                            fill="#000000" stroke-width="0" />
                                        <path d="M588.7,250.45h-11.6v-30.8h-4v-9.8h4v-13.7h11.6v13.7h7.1v9.8h-7.1v30.8Z"
                                            fill="#000000" stroke-width="0" />
                                        <path
                                            d="M637.4,250.45h-11.4v-3.7h-.2c-2.5,3.2-6.8,5-11,5-11.9,0-18.7-10.8-18.7-21.8s6.9-21.4,18.5-21.4c4.2,0,8.5,1.8,11.3,5.1v-3.8h11.4l.1,40.6h0ZM627.2,230.15c0-5.5-3.7-11.3-9.7-11.3s-9.6,5.9-9.6,11.3,3.6,11.4,9.6,11.4,9.7-6,9.7-11.4Z"
                                            fill="#000000" stroke-width="0" />
                                        <path d="M657.8,250.45h-11.6v-70.1h11.6v70.1Z" fill="#000000"
                                            stroke-width="0" />
                                    </svg>
                                    <span class="u-hiddenVisually">Wolfson Children's Hospital</span>
                                </div>
                                <div>
                                    <small>
                                        ©
                                        <span id="copyright">
                                            {{ currentYear }}
                                        </span>
                                        Baptist Health.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <!-- FooterSection End -->
    </div>
</template>

<script>
import NavSection from '@/components/NavSection.vue';
import QuestionSection from '@/components/QuestionSection.vue';
  import { ref } from 'vue';

  export default {
    name: 'WolfsonChildrens',
    components: {
        NavSection,
        QuestionSection,
    },
    data() {
      return {
        isHeaderVisible: true,
        isContentVisible: true,
      };
    },
    beforeRouteUpdate(to, from, next) {
        this.isHeaderVisible = false;
        next();
    },
    methods: {
        resetHeaderVisibility() {
            this.$router.push("/wolfson").then(() => {
                this.isHeaderVisible = true;
            }).catch(error => {
                console.error(error);
            });
        },
        toggleExpand() {
            this.isContentVisible = !this.isContentVisible;
        }
    },
    setup() {
        const currentYear = ref(new Date().getFullYear());
        return { currentYear };
    },
    mounted() {
      console.log('Wolfson Childrens mounted');
    }
  };
</script>