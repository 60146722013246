<template>
    <section class="Band-content u-bgWhite u-padTop8" id="logos">
        <div class="u-padSides8">
            <div class="u-containSpread">
                <HeaderSection :sectionTitle="sectionTitle" :headerColor="headerColor" />
                <p>
                    Maintain the original proportions of the Baptist Health logos to prevent them from appearing
                    stretched or compressed. Do not modify or recreate any Baptist Health logos. It should not be joined
                    or placed with any other logo or brandmark unless Creative Services has approved it.
                </p>
                <!-- <h2 class="u-padTop8">Primary Logo</h2> -->
                <div class="Grid Grid--withGap u-padTop3 u-lg-padTop2 u-padBottom10">

                    <div class="Grid-cell u-sm-sizefull u-padTop10 u-lg-padTop4">
                        <div class="u-textCenter">
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-sizeFull">
                                    <svg class="logo_sq" viewBox="0 0 399.38 435.65">
                                        <polygon
                                            points="325.41 102 257.56 101.96 257.56 83.07 316.09 83.07 331.13 99.45 334.96 103.36 334.86 168.86 328.23 176.87 328.23 102.13 325.41 102"
                                            fill="#0056a2" stroke-width="0" />
                                        <path
                                            d="M280.57,202.67l-17.51-42.56s-19.75-63.39-20.14-65.65h-3.11v9.26s-8.66-.05-8.66-.05v-5.99h-53.43V23.45h79.85L234.19,0h-92.61v81.87h-76.96v70.51l30.26,30.25v-80.48s11.05,0,11.05,0l5.03,7.35h39.89l11.69,9.32,31.5,2.9,13.12,31.72-3.89,10.84,9.17-8.79,4.65,4.61-9.92,14.42,18.17,46.82,30.99,23.08,8.34,15,8.11-17.26,9.42-12.42-1.64-27.08Z"
                                            fill="#0056a2" stroke-width="0" />
                                        <polygon
                                            points="175.82 184.19 138.54 184.15 138.52 220.85 177.07 259.38 250.61 259.28 242.56 251.27 175.83 251.27 175.82 184.19"
                                            fill="#0056a2" stroke-width="0" />
                                        <path
                                            d="M22.26,304.82c0-5.79.19-9.66,6.8-9.66,8.65,0,15.84,6.51,15.84,15.86,0,7.52-2.82,11.39-10.2,11.39h-5.83c-5.64-.41-6.61-.81-6.61-5.8v-11.8ZM10.3,350.68c0,9.86-8.36,7.12-8.36,10.47,0,1.42.97,1.63,3.21,1.63,2.04,0,4.57-.41,6.9-.41,4.86,0,9.72.41,24.2.41,15.84,0,24.78-8.75,24.78-22.17,0-9.15-3.69-14.64-15.55-17.79v-.2c6.9-2.34,10.59-7.93,10.59-14.34,0-2.44-.1-17.49-23.91-17.49-8.16,0-16.42.41-24.69.41-4.47,0-5.15-.41-5.83-.41-.49,0-1.65-.1-1.65,1.52,0,4.98,10.3-.61,10.3,13.42v44.95ZM22.26,334.82c0-5.29.19-7.83,5.93-7.83,17.69,0,20.51,9.15,20.51,15.25,0,9.87-4.96,16.47-14.48,16.47s-11.95-2.85-11.95-12.51v-11.39Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M152.89,290.79c-6.9.1-13.8.41-15.16.41-7.39,0-12.73-.41-13.41-.41-2.33,0-2.72,1.32-2.72,1.93,0,3.76,10.11-.41,10.11,10.37v44.74c0,8.96-3.86,10.49-6.14,11.65-1.69-.33-3.77-1.16-5.31-4.75l-26.24-62.23c-1.26-3.05-1.46-3.25-2.33-3.25-1.26,0-1.75,1.93-2.43,3.56l-26.34,61.52c-2.43,5.69-7.39,5.08-7.39,7.42,0,1.32,1.56,1.12,2.91,1.12,2.82,0,5.64-.41,8.55-.41,3.11,0,6.02.41,9.14.41,2.04,0,5.05.71,5.05-1.42,0-2.75-10.5-1.12-10.5-8.44,0-2.44,1.36-6,2.04-8.03,2.23-6.71,4.27-7.12,5.93-7.12h19.15c2.53,0,3.01,1.02,6.22,8.95,1.07,2.75,1.75,4.58,1.75,6.81,0,6.2-6.9,5.08-6.9,8.03,0,1.42,1.26,1.22,3.01,1.22,2.82,0,6.41-.41,10.69-.41,6.41,0,9.04.41,12.54.41,1.42,0,2.32.05,2.85-.17,2.63-.12,7.2-.32,9.97-.32,2.14,0,6.41.41,13.22.41,1.36,0,2.33.2,2.33-1.53,0-3.56-9.82.31-9.82-12.41v-47.18c0-4.58,1.56-6.61,5.93-6.61,15.06,0,15.94,14.85,15.84,17.49-.1,2.64-.97,14.13-12.73,14.13-2.14,0-3.79-.61-4.57-.61-1.17,0-1.36,1.22-1.36,1.93,0,.41.49,2.95,10.01,2.95,11.57,0,20.6-7.73,20.6-20.44,0-14.85-12.05-19.93-24.49-19.73ZM94.78,332.36h-13.22c-1.75,0-3.11.1-3.11-.91,0-1.42.58-2.54,1.07-3.76l7.48-18c.49-1.12.87-1.22,1.07-1.22.49,0,.19,0,.78.81l7.97,18.41c.49,1.22,1.07,2.34,1.07,3.76,0,1.02-1.36.91-3.11.91Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M208.77,349.44c0,12.1-10.98,7.22-10.98,11.18,0,2.24,1.65,2.14,3.4,2.14,3.89,0,7.1-.41,13.61-.41s9.72.41,13.61.41c2.82,0,4.76.3,4.76-2.14,0-2.85-12.44.1-12.44-12.4v-45.96c0-4.88.97-5.39,4.95-5.39h8.26c11.18-.41,8.07,10.88,11.95,10.88,2.53,0,1.46-3.97,1.26-5.19l-1.07-9.36c-.1-1.02-.1-2.44-1.56-2.44-9.04,0-15.55.41-29.74.41s-20.7-.41-29.74-.41c-1.46,0-1.46,1.42-1.56,2.44l-1.56,13.42c-.19,1.32-.78,3.56,1.26,3.56,4.37,0,1.46-13.32,12.44-13.32h8.16c3.99,0,4.96.51,4.96,5.39v47.18Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M273.48,301.67c0-9.66,7.29-6,7.29-9.46,0-1.22-.49-1.42-3.4-1.42-5.54,0-9.04.41-12.54.41s-7.19-.41-10.98-.41c-.87,0-3.3-.3-3.3,1.42,0,3.56,10.98.71,10.98,9.96v48.81c0,9.66-7,7.02-7,10.58,0,.51.19,1.22,1.07,1.22,2.53,0,6.71-.41,10.89-.41,7.58,0,11.18.41,14.77.41,2.24,0,3.11-.41,3.11-1.53,0-3.25-10.88,1.42-10.88-11.08v-48.5Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M289.12,355.99c.48,2.44.39,3.25,2.82,3.87,5.64,1.32,10.59,3.76,16.52,3.76,12.73,0,21.67-9.05,21.67-22.37s-6.42-17.29-16.81-22.07c-13.02-6-18.37-7.32-18.37-14.13s4.37-10.68,10.79-10.68c16.71,0,18.17,18.91,20.41,18.91s2.43-1.22,2.24-3.46l-1.07-13.63c-.19-2.34.1-3.86-1.36-3.86-1.26,0-3.69,1.63-4.95,1.63-2.92,0-7-3.96-14.97-3.96-11.37,0-19.15,8.54-19.15,20.24,0,10.68,5.73,14.64,14.09,18.51,15.65,7.22,21.77,8.54,21.77,17.18,0,8.14-5.83,13.02-13.41,13.02-10.79,0-16.82-9.25-19.92-19.01-.39-1.32-.58-2.24-2.14-2.24-2.43,0-1.46,2.95-1.16,4.37l3.01,13.93Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M360.6,349.46c0,12.1-10.98,7.22-10.98,11.19,0,2.24,1.65,2.14,3.4,2.14,3.89,0,7.1-.41,13.61-.41s9.72.41,13.61.41c2.82,0,4.76.3,4.76-2.14,0-2.85-12.44.1-12.44-12.41v-45.96c0-4.88.97-5.39,4.95-5.39h8.26c11.18-.41,8.07,10.88,11.95,10.88,2.53,0,1.46-3.97,1.26-5.19l-1.07-9.36c-.1-1.02-.1-2.44-1.56-2.44-9.04,0-15.55.41-29.74.41s-20.7-.41-29.74-.41c-1.46,0-1.46,1.42-1.55,2.44l-1.56,13.42c-.19,1.32-.78,3.56,1.26,3.56,4.37,0,1.46-13.32,12.44-13.32h8.16c3.99,0,4.96.51,4.96,5.39v47.18Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M96.94,385.31c0-8.06,5.66-4.53,5.66-7.65,0-.74-.31-.99-1.65-.99-3.38,0-6.29.33-10.38.33-4.88,0-6.92-.33-10.14-.33-1.1,0-1.81-.16-1.81,1.31,0,2.39,8.65-.58,8.65,8.48v12.22c0,1.81-.47,3.7-2.75,3.7h-23.35c-1.65,0-1.96-1.07-1.96-3.29v-13.7c0-7.32,6.05-4.94,6.05-7.49,0-.82-.71-1.23-1.81-1.23-2.28,0-5.42.33-10.14.33-4.09,0-6.45-.33-8.81-.33-1.49,0-3.85.08-3.85,1.31,0,3.13,8.89-.49,8.89,8.48v37.31c0,10.2-6.68,7.24-6.68,10.53,0,1.07.79,1.23,1.73,1.23,3.93,0,7.23-.33,10.54-.33,5.66,0,8.26.33,11.48.33,2.52,0,2.36-.66,2.36-1.15,0-2.96-9.75,0-9.75-10.2v-12.92c0-3.62.23-4.44,3.85-4.44h20.99c3.46,0,3.22.91,3.22,4.36v14.81c0,7.98-5.74,5.76-5.74,8.64,0,.58.47.91,1.57.91,4.01,0,7.08-.33,9.91-.33,3.22,0,7.86.33,10.46.33,1.02,0,1.65.08,1.65-.99,0-3.21-8.18-.16-8.18-10.61v-38.63Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M124.02,386.1c0-5.67.31-5.76,9.83-5.76,15.17,0,9.04,9.63,12.74,9.63,1.81,0,1.42-2.71,1.34-3.79l-.31-8.14c0-.74-.55-1.56-1.57-1.56-6.92,0-12.35.33-17.93.33-12.03,0-17.69-.33-20.92-.33-1.1,0-1.73.66-1.73,1.4,0,3.29,8.88.66,8.88,8.06v39.43c0,10.04-5.97,5.59-5.97,9.38,0,.49.47.91,1.49.91,4.32,0,7.63-.33,10.06-.33,3.93,0,8.65.33,20.6.33,6.45,0,11.09-6.75,11.09-13.17,0-1.56-.55-1.81-.87-1.81-2.99,0-1.89,10.78-21.39,10.78-5.66,0-5.35-2.96-5.35-8.06v-10.53c0-4.77-.31-5.76,3.3-5.76h5.82c8.18,0,5.97,8.72,8.49,8.72,1.89,0,1.42-2.96,1.34-4.03l-.55-14.64c-.08-2.22-.94-2.06-1.49-2.06-1.57,0-1.81,7.57-8.57,7.57h-4.95c-3.38,0-3.38-.58-3.38-4.11v-12.45Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M250.45,420.32c-2.99,0-1.89,10.78-21.39,10.78-5.66,0-5.35-2.96-5.35-8.06v-39.06c0-6.25,5.58-3.21,5.58-6.33,0-.99-.39-1.07-2.04-1.07-1.26,0-2.2.33-8.89.33-2.99,0-7.55-.33-10.69-.33-1.65,0-2.52.66-2.52,1.4,0,2.88,8.89,1.13,8.89,8.12v38.92c0,7.73-3.54,6.87-5.17,7.8-1.47-.23-3.65-.42-5.18-3.99l-21.23-50.35c-1.02-2.47-1.18-2.63-1.89-2.63-1.02,0-1.42,1.56-1.97,2.88l-21.31,49.78c-1.97,4.61-5.98,4.12-5.98,6.01,0,1.07,1.26.91,2.36.91,2.28,0,4.56-.33,6.92-.33,2.52,0,4.88.33,7.39.33,1.65,0,4.09.58,4.09-1.15,0-2.22-8.49-.9-8.49-6.83,0-1.97,1.1-4.85,1.65-6.5,1.81-5.43,3.46-5.76,4.8-5.76h15.49c2.05,0,2.44.82,5.03,7.24.87,2.22,1.42,3.7,1.42,5.51,0,5.02-5.58,4.11-5.58,6.5,0,1.15,1.02.99,2.44.99,2.28,0,5.19-.33,8.65-.33,5.19,0,7.31.33,10.14.33,1.1,0,1.81.04,2.25-.11,4.17-.02,7.37-.33,9.75-.33,3.93,0,8.65.33,20.6.33,6.45,0,11.09-6.75,11.09-13.16,0-1.56-.55-1.81-.86-1.81ZM183.09,410.72h-10.69c-1.41,0-2.52.08-2.52-.74,0-1.15.47-2.06.87-3.05l6.05-14.56c.39-.91.71-.99.86-.99.39,0,.16,0,.63.66l6.45,14.89c.39.99.87,1.89.87,3.05,0,.82-1.1.74-2.52.74Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M262.4,424c0,9.79-8.88,5.84-8.88,9.05,0,1.81,1.34,1.73,2.75,1.73,3.14,0,5.74-.33,11.01-.33s7.86.33,11.01.33c2.28,0,3.85.25,3.85-1.73,0-2.3-10.07.08-10.07-10.04v-37.19c0-3.95.79-4.36,4.01-4.36h6.68c9.04-.33,6.53,8.8,9.67,8.8,2.04,0,1.18-3.21,1.02-4.2l-.86-7.57c-.08-.82-.08-1.97-1.26-1.97-7.31,0-12.58.33-24.06.33s-16.75-.33-24.06-.33c-1.18,0-1.18,1.15-1.26,1.97l-1.26,10.86c-.16,1.07-.63,2.88,1.02,2.88,3.54,0,1.18-10.78,10.06-10.78h6.61c3.22,0,4.01.41,4.01,4.36v38.17Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M351.05,385.16c0-8.06,5.66-4.52,5.66-7.65,0-.74-.31-.99-1.65-.99-3.38,0-6.29.33-10.38.33-4.88,0-6.92-.33-10.14-.33-1.1,0-1.81-.17-1.81,1.32,0,2.38,8.65-.58,8.65,8.47v11.6c0,1.81-.47,3.7-2.75,3.7h-23.35c-1.65,0-1.96-1.07-1.96-3.29v-13.08c0-7.32,6.05-4.94,6.05-7.49,0-.82-.71-1.23-1.81-1.23-2.28,0-5.42.33-10.14.33-4.09,0-6.45-.33-8.81-.33-1.49,0-3.85.08-3.85,1.32,0,3.13,8.89-.49,8.89,8.47v36.69c0,10.2-6.68,7.24-6.68,10.53,0,1.07.79,1.24,1.73,1.24,3.93,0,7.23-.33,10.54-.33,5.66,0,8.26.33,11.48.33,2.52,0,2.36-.66,2.36-1.15,0-2.96-9.75,0-9.75-10.2v-12.92c0-3.62.24-4.44,3.85-4.44h20.99c3.46,0,3.23.91,3.23,4.36v14.81c0,7.98-5.74,5.76-5.74,8.64,0,.58.47.91,1.57.91,4.01,0,7.08-.33,9.91-.33,3.23,0,7.86.33,10.46.33,1.02,0,1.65.08,1.65-.99,0-3.21-8.18-.16-8.18-10.61v-38.01Z"
                                            fill="#000" stroke-width="0" />
                                    </svg>
                                </div>
                                <div class="Grid-cell u-containProse u-padTop5">
                                    <a
                                        href="https://cdn.baptistjax.com/raw/upload/v1713498548/BrandGuidelines/Logos/BaptistHealth_logos.zip"><button
                                            type="button"
                                            class="Button Button--tertiary u-textSize1 u-textCenter u-sizeFull u-borderColorBlackAlt u-textBlackAlt u-textUpper"
                                            value="Download Icons">Download Logo Files</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="Grid Grid--withGap u-padTop4">
                    <p class="u-padTop10">
                        The logo can be enlarged or reduced as long as its original proportions are maintained. It
                        should never be distorted in any way.
                    </p>
                    <p>
                        <strong>Logo versions available</strong>
                        Master files of all approved Baptist Health logos are available in a variety of formats, each
                        download will contain all color profiles and file formats, including:
                    </p><br />
                    <p>
                        <strong>Logo versions include:</strong>
                    <ul class="u-padLeft8">
                        <li>Color</li>
                        <li>Black & white (grayscale)</li>
                        <li>Reverse (white)</li>
                    </ul>
                    </p><br />
                    <p>
                        <strong>File formats</strong>
                    <ul class="u-padLeft8">
                        <li>.JPG (for general use)</li>
                        <li>.PNG (for digital use)</li>
                        <li>.EPS (for professional/vendor use)</li>
                    </ul>
                    </p>

                    <section>
                        <AccordionSection title="How to place a logo">
                            <p>
                                Start by opening a document in your preferred software, such as Microsoft Word or
                                PowerPoint. It's a straightforward process. Just follow the chart above to select and
                                insert the correct logo file, which should open immediately.
                            </p>
                            <p>
                                To resize a logo while maintaining its proportions:
                            </p>
                            <ul class="u-padLeft8">
                                <li>Select the logo.</li>
                                <li>Hold down the "SHIFT" key and grab a corner of the logo.</li>
                                <li>Drag the corner towards the center of the logo. Remember, never alter the logo's
                                    proportions, which could make it appear compressed or extended.</li>
                            </ul>
                            <p>
                                Always use .jpg logos at their original size or smaller to ensure the logo retains its
                                quality. Always scale down from a larger format instead of enlarging a smaller logo.
                            </p>
                        </AccordionSection>
                        <AccordionSection title="Logo size and clear space">
                            <div>
                                <p>
                                    Ensure the logo is always at least 5/8" wide to maintain legibility.
                                </p>
                                <p>
                                    Clear space, or "breathing room," is crucial around our logo. It enhances the logo's
                                    visibility and impact by freeing it from surrounding text or images. This space also
                                    sets the minimum distance between the logo and the edge of any printed material. For
                                    Baptist Health logos, the minimum clear space required is equal to half the width of
                                    the cross. More space around the logo is always preferable for optimal clarity and
                                    effectiveness.
                                </p>
                                <div class="Grid Grid--withGap u-textCenter u-padTop8">
                                    <div class="Grid-cell  u-sizeFull u-lg-size1of2">
                                        <svg class="logo_sq_clear" viewBox="0 0 867.44 863.35">
                                            <g id="b">
                                                <g id="c">
                                                    <rect width="867.44" height="863.35" fill="#efefef"
                                                        stroke-width="0" />
                                                    <rect x="110.03" y="94.46" width="643.02" height="669.31"
                                                        fill="#fff" stroke-width="0" />
                                                </g>
                                            </g>
                                            <g id="d">
                                                <g id="e">
                                                    <polygon
                                                        points="556.01 321.05 488.16 321.01 488.16 302.12 546.69 302.12 561.73 318.49 565.56 322.41 565.46 387.9 558.83 395.92 558.83 321.17 556.01 321.05"
                                                        fill="#0056a1" stroke-width="0" />
                                                    <path
                                                        d="M511.17,421.71l-17.51-42.56s-19.75-63.39-20.14-65.65h-3.11v9.26s-8.66-.05-8.66-.05v-5.99h-53.43v-74.22h79.85l-23.38-23.46-92.6.02v81.86h-76.96v70.51l30.26,30.25v-80.48s11.05,0,11.05,0l5.03,7.35h39.89l11.69,9.32,31.5,2.9,13.12,31.72-3.89,10.84,9.17-8.79,4.65,4.61-9.92,14.42,18.17,46.83,30.99,23.08,8.34,15,8.11-17.26,9.42-12.42-1.64-27.09Z"
                                                        fill="#0056a1" stroke-width="0" />
                                                    <polygon
                                                        points="406.41 403.23 369.13 403.2 369.11 439.9 407.67 478.42 481.21 478.32 473.16 470.31 406.43 470.31 406.41 403.23"
                                                        fill="#0056a1" stroke-width="0" />
                                                    <path
                                                        d="M252.85,513.86c0-5.79.19-9.66,6.8-9.66,8.65,0,15.84,6.51,15.84,15.86,0,7.52-2.82,11.39-10.2,11.39h-5.83c-5.64-.41-6.61-.81-6.61-5.8,0,0,0-11.79,0-11.79ZM240.9,559.72c0,9.86-8.36,7.12-8.36,10.47,0,1.42.97,1.63,3.21,1.63,2.04,0,4.57-.41,6.9-.41,4.86,0,9.72.41,24.2.41,15.84,0,24.78-8.75,24.78-22.17,0-9.15-3.69-14.64-15.55-17.79v-.2c6.9-2.34,10.59-7.93,10.59-14.34,0-2.44-.1-17.49-23.91-17.49-8.16,0-16.42.41-24.69.41-4.47,0-5.15-.41-5.83-.41-.49,0-1.65-.1-1.65,1.52,0,4.98,10.3-.61,10.3,13.42v44.95h0ZM252.85,543.86c0-5.29.19-7.83,5.93-7.83,17.69,0,20.51,9.15,20.51,15.25,0,9.87-4.96,16.47-14.48,16.47s-11.95-2.85-11.95-12.51v-11.38h0Z"
                                                        fill="#231f20" stroke-width="0" />
                                                    <path
                                                        d="M383.49,499.83c-6.9.1-13.8.41-15.16.41-7.39,0-12.73-.41-13.41-.41-2.33,0-2.72,1.32-2.72,1.93,0,3.76,10.11-.41,10.11,10.37v44.74c0,8.96-3.86,10.49-6.14,11.65-1.69-.33-3.77-1.16-5.31-4.75l-26.24-62.23c-1.26-3.05-1.46-3.25-2.33-3.25-1.26,0-1.75,1.93-2.43,3.56l-26.34,61.52c-2.43,5.69-7.39,5.08-7.39,7.42,0,1.32,1.56,1.12,2.91,1.12,2.82,0,5.64-.41,8.55-.41,3.11,0,6.02.41,9.14.41,2.04,0,5.05.71,5.05-1.42,0-2.75-10.5-1.12-10.5-8.44,0-2.44,1.36-6,2.04-8.03,2.23-6.71,4.27-7.12,5.93-7.12h19.15c2.53,0,3.01,1.02,6.22,8.95,1.07,2.75,1.75,4.58,1.75,6.81,0,6.2-6.9,5.08-6.9,8.03,0,1.42,1.26,1.22,3.01,1.22,2.82,0,6.41-.41,10.69-.41,6.41,0,9.04.41,12.54.41,1.42,0,2.32.05,2.85-.17,2.63-.12,7.2-.32,9.97-.32,2.14,0,6.41.41,13.22.41,1.36,0,2.33.2,2.33-1.53,0-3.56-9.82.31-9.82-12.41v-47.18c0-4.58,1.56-6.61,5.93-6.61,15.06,0,15.94,14.85,15.84,17.49s-.97,14.13-12.73,14.13c-2.14,0-3.79-.61-4.57-.61-1.17,0-1.36,1.22-1.36,1.93,0,.41.49,2.95,10.01,2.95,11.57,0,20.6-7.73,20.6-20.44,0-14.84-12.05-19.92-24.49-19.72ZM325.38,541.41h-13.22c-1.75,0-3.11.1-3.11-.92,0-1.42.58-2.54,1.07-3.76l7.48-18c.49-1.12.87-1.22,1.07-1.22.49,0,.19,0,.78.81l7.97,18.41c.49,1.22,1.07,2.34,1.07,3.76,0,1.02-1.36.92-3.11.92Z"
                                                        fill="#231f20" stroke-width="0" />
                                                    <path
                                                        d="M439.37,558.49c0,12.1-10.98,7.22-10.98,11.18,0,2.24,1.65,2.14,3.4,2.14,3.89,0,7.1-.41,13.61-.41s9.72.41,13.61.41c2.82,0,4.76.3,4.76-2.14,0-2.85-12.44.1-12.44-12.4v-45.97c0-4.88.97-5.39,4.95-5.39h8.26c11.18-.41,8.07,10.88,11.95,10.88,2.53,0,1.46-3.97,1.26-5.19l-1.07-9.36c-.1-1.02-.1-2.44-1.56-2.44-9.04,0-15.55.41-29.74.41s-20.7-.41-29.74-.41c-1.46,0-1.46,1.42-1.56,2.44l-1.56,13.42c-.19,1.32-.78,3.56,1.26,3.56,4.37,0,1.46-13.32,12.44-13.32h8.16c3.99,0,4.96.51,4.96,5.39v47.2h.03Z"
                                                        fill="#231f20" stroke-width="0" />
                                                    <path
                                                        d="M504.08,510.71c0-9.66,7.29-6,7.29-9.46,0-1.22-.49-1.42-3.4-1.42-5.54,0-9.04.41-12.54.41s-7.19-.41-10.98-.41c-.87,0-3.3-.3-3.3,1.42,0,3.56,10.98.71,10.98,9.96v48.81c0,9.66-7,7.02-7,10.58,0,.51.19,1.22,1.07,1.22,2.53,0,6.71-.41,10.89-.41,7.58,0,11.18.41,14.77.41,2.24,0,3.11-.41,3.11-1.53,0-3.25-10.88,1.42-10.88-11.09v-48.49h-.01Z"
                                                        fill="#231f20" stroke-width="0" />
                                                    <path
                                                        d="M519.71,565.03c.48,2.44.39,3.25,2.82,3.87,5.64,1.32,10.59,3.76,16.52,3.76,12.73,0,21.67-9.05,21.67-22.37s-6.42-17.29-16.81-22.07c-13.02-6-18.37-7.32-18.37-14.13s4.37-10.68,10.79-10.68c16.71,0,18.17,18.91,20.41,18.91s2.43-1.22,2.24-3.46l-1.07-13.63c-.19-2.34.1-3.86-1.36-3.86-1.26,0-3.69,1.63-4.96,1.63-2.92,0-7-3.96-14.97-3.96-11.37,0-19.15,8.54-19.15,20.24,0,10.68,5.73,14.64,14.09,18.51,15.65,7.22,21.77,8.54,21.77,17.18,0,8.14-5.83,13.02-13.41,13.02-10.79,0-16.82-9.25-19.92-19.01-.39-1.32-.58-2.24-2.14-2.24-2.43,0-1.46,2.95-1.17,4.37l3.02,13.92Z"
                                                        fill="#231f20" stroke-width="0" />
                                                    <path
                                                        d="M591.2,558.51c0,12.1-10.98,7.22-10.98,11.19,0,2.24,1.65,2.14,3.4,2.14,3.89,0,7.1-.41,13.61-.41s9.72.41,13.61.41c2.82,0,4.76.3,4.76-2.14,0-2.85-12.44.1-12.44-12.41v-45.96c0-4.88.97-5.39,4.95-5.39h8.26c11.18-.41,8.07,10.88,11.95,10.88,2.53,0,1.46-3.97,1.26-5.19l-1.07-9.36c-.1-1.02-.1-2.44-1.56-2.44-9.04,0-15.55.41-29.74.41s-20.7-.41-29.74-.41c-1.46,0-1.46,1.42-1.55,2.44l-1.56,13.42c-.19,1.32-.78,3.56,1.26,3.56,4.37,0,1.46-13.32,12.44-13.32h8.16c3.99,0,4.96.51,4.96,5.39v47.19h.02Z"
                                                        fill="#231f20" stroke-width="0" />
                                                    <path
                                                        d="M327.54,594.35c0-8.06,5.66-4.53,5.66-7.65,0-.74-.31-.99-1.65-.99-3.38,0-6.29.33-10.38.33-4.88,0-6.92-.33-10.14-.33-1.1,0-1.81-.16-1.81,1.31,0,2.39,8.65-.58,8.65,8.48v12.22c0,1.81-.47,3.7-2.75,3.7h-23.36c-1.65,0-1.96-1.07-1.96-3.29v-13.7c0-7.32,6.05-4.94,6.05-7.49,0-.82-.71-1.23-1.81-1.23-2.28,0-5.42.33-10.14.33-4.09,0-6.45-.33-8.81-.33-1.49,0-3.85.08-3.85,1.31,0,3.13,8.89-.49,8.89,8.48v37.31c0,10.2-6.68,7.24-6.68,10.53,0,1.07.79,1.23,1.73,1.23,3.93,0,7.23-.33,10.54-.33,5.66,0,8.26.33,11.48.33,2.52,0,2.36-.66,2.36-1.15,0-2.96-9.75,0-9.75-10.2v-12.92c0-3.62.23-4.44,3.85-4.44h20.99c3.46,0,3.22.91,3.22,4.36v14.81c0,7.98-5.74,5.76-5.74,8.64,0,.58.47.91,1.57.91,4.01,0,7.08-.33,9.91-.33,3.22,0,7.86.33,10.46.33,1.02,0,1.65.08,1.65-.99,0-3.21-8.18-.16-8.18-10.61,0,0,0-38.63,0-38.63Z"
                                                        fill="#231f20" stroke-width="0" />
                                                    <path
                                                        d="M354.62,595.14c0-5.67.31-5.76,9.83-5.76,15.17,0,9.04,9.63,12.74,9.63,1.81,0,1.42-2.71,1.34-3.79l-.31-8.14c0-.74-.55-1.56-1.57-1.56-6.92,0-12.35.33-17.93.33-12.03,0-17.69-.33-20.92-.33-1.1,0-1.73.66-1.73,1.4,0,3.29,8.88.66,8.88,8.06v39.43c0,10.04-5.97,5.59-5.97,9.38,0,.49.47.91,1.49.91,4.32,0,7.63-.33,10.06-.33,3.93,0,8.65.33,20.6.33,6.45,0,11.09-6.75,11.09-13.16,0-1.56-.55-1.81-.87-1.81-2.99,0-1.89,10.78-21.39,10.78-5.66,0-5.35-2.96-5.35-8.06v-10.55c0-4.77-.31-5.76,3.3-5.76h5.82c8.18,0,5.97,8.72,8.49,8.72,1.89,0,1.42-2.96,1.34-4.03l-.55-14.64c-.08-2.22-.94-2.06-1.49-2.06-1.57,0-1.81,7.57-8.57,7.57h-4.95c-3.38,0-3.38-.58-3.38-4.11v-12.45h0Z"
                                                        fill="#231f20" stroke-width="0" />
                                                    <path
                                                        d="M481.04,629.36c-2.99,0-1.89,10.78-21.39,10.78-5.66,0-5.35-2.96-5.35-8.06v-39.06c0-6.25,5.58-3.21,5.58-6.33,0-.99-.39-1.07-2.04-1.07-1.26,0-2.2.33-8.89.33-2.99,0-7.55-.33-10.69-.33-1.65,0-2.52.66-2.52,1.4,0,2.88,8.89,1.13,8.89,8.12v38.92c0,7.73-3.54,6.88-5.17,7.8-1.47-.23-3.65-.42-5.18-3.99l-21.23-50.35c-1.02-2.47-1.18-2.63-1.89-2.63-1.02,0-1.42,1.56-1.97,2.88l-21.31,49.78c-1.97,4.61-5.98,4.12-5.98,6.01,0,1.07,1.26.91,2.36.91,2.28,0,4.56-.33,6.92-.33,2.52,0,4.88.33,7.39.33,1.65,0,4.09.58,4.09-1.15,0-2.22-8.49-.9-8.49-6.83,0-1.97,1.1-4.85,1.65-6.5,1.81-5.43,3.46-5.76,4.8-5.76h15.49c2.05,0,2.44.82,5.03,7.24.87,2.22,1.42,3.7,1.42,5.51,0,5.02-5.58,4.11-5.58,6.5,0,1.15,1.02.99,2.44.99,2.28,0,5.19-.33,8.65-.33,5.19,0,7.31.33,10.14.33,1.1,0,1.81.04,2.25-.11,4.17-.02,7.37-.33,9.75-.33,3.93,0,8.65.33,20.6.33,6.45,0,11.09-6.75,11.09-13.16.01-1.59-.54-1.84-.86-1.84ZM413.69,619.76h-10.7c-1.41,0-2.52.08-2.52-.74,0-1.15.47-2.06.87-3.05l6.05-14.56c.39-.91.71-.99.86-.99.39,0,.16,0,.63.66l6.45,14.89c.39.99.87,1.89.87,3.05,0,.83-1.1.74-2.51.74Z"
                                                        fill="#231f20" stroke-width="0" />
                                                    <path
                                                        d="M493,633.04c0,9.79-8.88,5.84-8.88,9.05,0,1.81,1.34,1.73,2.75,1.73,3.14,0,5.74-.33,11.01-.33s7.86.33,11.01.33c2.28,0,3.85.25,3.85-1.73,0-2.3-10.07.08-10.07-10.04v-37.19c0-3.95.79-4.36,4.01-4.36h6.68c9.04-.33,6.53,8.8,9.67,8.8,2.04,0,1.18-3.21,1.02-4.2l-.86-7.57c-.08-.82-.08-1.97-1.26-1.97-7.31,0-12.58.33-24.06.33s-16.75-.33-24.06-.33c-1.18,0-1.18,1.15-1.26,1.97l-1.26,10.87c-.16,1.07-.63,2.88,1.02,2.88,3.54,0,1.18-10.78,10.06-10.78h6.6c3.22,0,4.01.41,4.01,4.36v38.18h.02Z"
                                                        fill="#231f20" stroke-width="0" />
                                                    <path
                                                        d="M581.64,594.21c0-8.06,5.66-4.52,5.66-7.65,0-.74-.31-.99-1.65-.99-3.38,0-6.29.33-10.38.33-4.88,0-6.92-.33-10.14-.33-1.1,0-1.81-.17-1.81,1.32,0,2.38,8.65-.58,8.65,8.47v11.6c0,1.81-.47,3.7-2.75,3.7h-23.35c-1.65,0-1.96-1.07-1.96-3.29v-13.08c0-7.32,6.05-4.94,6.05-7.49,0-.82-.71-1.23-1.81-1.23-2.28,0-5.42.33-10.14.33-4.09,0-6.45-.33-8.81-.33-1.49,0-3.85.08-3.85,1.32,0,3.13,8.89-.49,8.89,8.47v36.69c0,10.2-6.68,7.24-6.68,10.53,0,1.07.79,1.24,1.73,1.24,3.93,0,7.23-.33,10.54-.33,5.66,0,8.26.33,11.48.33,2.52,0,2.36-.66,2.36-1.15,0-2.96-9.75,0-9.75-10.2v-12.92c0-3.62.24-4.44,3.85-4.44h20.99c3.46,0,3.23.91,3.23,4.36v14.81c0,7.98-5.74,5.76-5.74,8.64,0,.58.47.91,1.57.91,4.01,0,7.08-.33,9.91-.33,3.23,0,7.86.33,10.46.33,1.02,0,1.65.08,1.65-.99,0-3.21-8.18-.17-8.18-10.61v-38.02h-.02Z"
                                                        fill="#231f20" stroke-width="0" />
                                                </g>
                                            </g>
                                        </svg>

                                    </div>
                                    <!-- <div class="Grid-cell u-sizeFull u-lg-size1of2">
                                        <svg class="logo_rect_clear" viewBox="0 0 286.21 118.99">
                                            <g id="b">
                                                <rect width="286.21" height="118.99" fill="#efefef" stroke-width="0" />
                                                <rect x="17.01" y="15.28" width="252.1" height="86.4" fill="#fff"
                                                    stroke-width="0" />
                                            </g>
                                            <g id="c">
                                                <polygon
                                                    points="81.61 54.18 71.01 54.18 71.01 51.28 80.11 51.28 82.51 53.78 83.11 54.48 83.01 64.68 82.01 65.88 82.01 54.28 81.61 54.18"
                                                    fill="#0056a2" stroke-width="0" />
                                                <path
                                                    d="M74.61,69.88l-2.7-6.6s-3.1-9.9-3.1-10.2h-.6v1.4h-1.4v-.9h-8.3v-11.6h12.4l-3.6-3.7h-14.4v12.8h-12v11l4.7,4.7v-12.5h1.7l.8,1.1h6.2l1.8,1.5,4.9.5,2,4.9-.6,1.7,1.4-1.4.7.7-1.5,2.2,2.8,7.3,4.8,3.6,1.3,2.3,1.3-2.7,1.5-1.9-.1-4.2h0Z"
                                                    fill="#0056a2" stroke-width="0" />
                                                <polygon
                                                    points="58.31 66.98 52.51 66.98 52.41 72.78 58.51 78.78 69.91 78.68 68.71 77.48 58.31 77.48 58.31 66.98"
                                                    fill="#0056a2" stroke-width="0" />
                                                <path
                                                    d="M110.01,63.68c0,2-.9,3.5-2.8,4.4-.9.5-2.2.8-3.9.9h-7.8c-.3,0-.5-.1-.5-.2s.3-.2.9-.2c.7,0,1.1-.1,1.3-.2.1-.1.2-.6.2-1.3v-16c0-.9,0-1.4-.1-1.5-.1-.2-.7-.4-1.5-.4-.5,0-.8-.1-.8-.3s.2-.3.8-.3h.4c.2.1.4.1.6.1h5.8c2.2,0,3.8.4,4.8,1.1,1.2.8,1.9,2,1.9,3.5,0,1.3-.5,2.4-1.5,3.3-1,.9-2.4,1.4-4.3,1.6v.2c1.8.1,3.2.5,4.2,1.1,1.5.8,2.3,2.3,2.3,4.2ZM105.91,53.48c0-1.9-.4-3.1-1.3-3.8-.5-.3-1.3-.5-2.6-.5-.3,0-.9,0-1.6.1v8.5h.3l1.7-.1c1.1,0,2-.4,2.7-1.2s.8-1.7.8-3h0ZM106.81,63.28c0-2.5-1-4-3.1-4.6-.6-.2-1.7-.3-3.3-.3v8.8c0,.6.1.9.2,1,.2.1.8.2,1.7.2,1.4,0,2.5-.4,3.3-1.3.8-.8,1.2-2.1,1.2-3.8h0Z"
                                                    fill="#231f20" stroke-width="0" />
                                                <path
                                                    d="M123.51,67.68c0,.3-.3.7-1.1,1s-1.4.5-2,.5c-1.4,0-2.1-.7-2.2-2.1-.8,1.5-1.9,2.2-3.5,2.2-.8,0-1.6-.2-2.1-.8s-.8-1.1-.8-1.9c0-1.6,1-2.9,2.9-3.8.7-.3,1.8-.6,3.5-.9v-.7c0-1.7-.1-2.9-.3-3.4-.3-1-1-1.5-2.2-1.5-.7,0-1.3.2-1.8.6s-.7.8-.7,1.4c0,.2.1.2.4.2s.5.1.7.3.3.5.3.8c0,.9-.4,1.3-1.3,1.3-.5,0-.8-.1-1.1-.5-.3-.3-.4-.7-.4-1.2,0-.9.4-1.7,1.1-2.4.8-.7,2-1.1,3.4-1.1,2.2,0,3.5.6,4,1.8.2.5.3,1.3.3,2.7v7.1c.1.7.4,1.1.9,1.1.6,0,1-.2,1.4-.6.2-.2.3-.3.4-.3s.2,0,.2.2h0ZM118.21,64.58v-2.1c-1.5.3-2.5.8-3.2,1.3s-1,1.3-1,2.2c0,.6.2,1.1.6,1.5.4.4.8.6,1.4.6.8,0,1.5-.5,2-1.4.2-.3.3-1,.2-2.1h0Z"
                                                    fill="#231f20" stroke-width="0" />
                                                <path
                                                    d="M135.61,62.48c0,2-.6,3.6-1.7,4.9-1.1,1.3-2.5,2-4.2,2-1.3,0-2.3-.5-3.1-1.4v6.8c0,.4,0,.7.1.8,0,.1.1.2.3.2.3.1.9.1,1.8.1.2,0,.4.1.4.3,0,.1-.1.2-.4.2h-5.9c-.6,0-.9-.1-.9-.2,0-.2.1-.3.4-.3h.9c.6,0,.9-.1.9-.4v-17.4c0-.5-.3-.8-.8-.8h-1.3c-.2,0-.3-.1-.3-.2,0-.2.2-.3.7-.3,1.1,0,2.1-.2,3-.8.3-.2.5-.2.6-.2.2,0,.3.1.3.2s.1.6.1,1.3c.7-1,1.8-1.6,3.2-1.6,1.7,0,3.2.6,4.3,1.9,1.1,1.4,1.6,3,1.6,4.9h0ZM133.01,62.48c0-1.5-.2-2.8-.7-3.9-.6-1.5-1.6-2.2-3-2.2-.7,0-1.3.2-1.8.7s-.8,1.1-.8,1.8v5.4c0,1.4.1,2.2.1,2.5s.2.6.4.9c.6.8,1.3,1.2,2.3,1.2s1.8-.4,2.3-1.3c.8-1.3,1.2-3,1.2-5.1h0Z"
                                                    fill="#231f20" stroke-width="0" />
                                                <path
                                                    d="M145.01,67.28c0,.2-.2.5-.8.9-.9.8-1.9,1.2-3.1,1.2-1.1,0-1.9-.3-2.5-.8-.3-.3-.5-1.2-.5-2.8v-8.7c0-.2-.1-.3-.3-.3h-1.1c-.4,0-.7-.1-.7-.2s.1-.2.3-.2c1.6-.1,2.8-.9,3.6-2.3.1-.2.3-.3.4-.3s.2.1.2.2v.6c0,.8,0,1.4.1,1.6.2,0,.5.1.9.1,1.1,0,1.8,0,2.2-.2h.2c.2,0,.2.1.2.2,0,.3-.8.4-2.4.4h-1.2v5.4c0,2.7.1,4.5.2,5.2.1.7.6,1,1.4,1,.7,0,1.4-.4,2.2-1.1.1-.1.2-.1.3-.1.3,0,.4.1.4.2h0Z"
                                                    fill="#231f20" stroke-width="0" />
                                                <path
                                                    d="M151.01,68.78c0,.2-.2.3-.5.3,0,0-.2,0-.7-.1-.6,0-1.1-.1-1.5-.1h-3.1c-.1.1-.2.1-.4.1s-.3-.1-.3-.2c0-.2.2-.3.6-.3.8,0,1.2-.1,1.2-.2.1-.1.1-.5.1-1.2v-8.9c0-.5,0-.8-.1-.9s-.2-.1-.4-.1h-1.2c-.2,0-.3-.1-.3-.2s.2-.2.5-.2c1.3-.1,2.4-.4,3.4-.9l.3-.2c.2.3.2.8.2,1.6v10.9c0,.2.1.2.3.3.3.1.7.1,1.3.1.4,0,.6,0,.6.2h0ZM148.71,50.48c0,.9-.4,1.3-1.3,1.3-.4,0-.7-.1-1-.4-.3-.2-.4-.5-.4-.9,0-.9.4-1.4,1.4-1.4.9.1,1.3.5,1.3,1.4h0Z"
                                                    fill="#231f20" stroke-width="0" />
                                                <path
                                                    d="M160.71,65.28c0,1.1-.4,2.1-1.3,2.9-.8.8-1.8,1.2-3,1.2-.7,0-1.5-.2-2.5-.7-.3-.1-.5-.2-.7-.2-.3,0-.5.2-.6.6-.1.2-.2.3-.3.3s-.2-.1-.2-.2v-3.9c0-.2.1-.3.2-.3.2,0,.2.1.3.3.1.9.4,1.6.8,2.1.8,1,1.9,1.5,3.2,1.5.7,0,1.3-.2,1.7-.6s.7-1,.7-1.7c0-.8-.2-1.4-.8-1.8-.3-.2-.5-.4-.8-.5-.2-.1-.9-.4-2-.8-2.2-.8-3.3-2.1-3.3-3.7,0-1.1.4-2,1.1-2.8s1.7-1.1,2.8-1.1c.4,0,1,.1,1.8.3.2.1.4.1.5.1.4,0,.7-.2.8-.6.1-.2.2-.3.3-.3s.2.1.2.2v1.4c0,.5,0,1.2.2,2.3v.1c0,.2-.1.2-.2.2s-.3-.3-.4-.9c-.2-.7-.6-1.3-1.1-1.7-.6-.4-1.2-.7-1.9-.7s-1.2.2-1.7.6c-.4.4-.7.9-.7,1.6,0,1,.7,1.7,2,2.2,2.1.8,3.4,1.5,3.9,2,.6.7,1,1.6,1,2.6h0Z"
                                                    fill="#231f20" stroke-width="0" />
                                                <path
                                                    d="M169.81,67.28c0,.2-.2.5-.8.9-.9.8-1.9,1.2-3.1,1.2-1.1,0-1.9-.3-2.5-.8-.3-.3-.5-1.2-.5-2.8v-8.7c0-.2-.1-.3-.3-.3h-1.1c-.4,0-.7-.1-.7-.2s.1-.2.3-.2c1.6-.1,2.8-.9,3.6-2.3.1-.2.3-.3.4-.3s.2.1.2.2v.6c0,.8,0,1.4.1,1.6.2,0,.5.1.9.1,1.1,0,1.8,0,2.2-.2h.2c.2,0,.2.1.2.2,0,.3-.8.4-2.4.4h-1.2v5.4c0,2.7.1,4.5.2,5.2.1.7.6,1,1.4,1,.7,0,1.4-.4,2.2-1.1.1-.1.2-.1.3-.1.4,0,.5.1.4.2h0Z"
                                                    fill="#231f20" stroke-width="0" />
                                                <path
                                                    d="M194.01,68.68c0,.2-.2.3-.6.3h-6.5c-.4,0-.6-.1-.6-.2,0-.2.5-.3,1.4-.3.5,0,.8-.1.9-.2.1-.1.1-.6.1-1.4v-8.5h-9v8.3c0,.9.1,1.4.2,1.6.1.2.6.3,1.4.3.5,0,.8.1.8.3s-.2.3-.6.3h-6.5c-.4,0-.6-.1-.6-.2s.2-.2.6-.3c.3,0,.5,0,.8-.1.4,0,.7-.1.8-.3.1-.1.1-.6.1-1.6v-15.7c0-.9,0-1.4-.1-1.5-.1-.2-.4-.2-.9-.3-.6,0-1-.1-1.1-.1s-.2-.1-.2-.2c0-.2.1-.2.4-.2h.5c.5,0,1.7.1,3.5.1h2.6c.4,0,.5.1.5.3s-.3.3-1,.3-1,.1-1.2.2c-.1.1-.2.6-.2,1.4v6.8h9v-6.3c0-1-.1-1.7-.2-1.8-.2-.2-.7-.3-1.7-.2-.3,0-.4-.1-.4-.3s.2-.3.5-.3h6.5c.4,0,.6.1.6.3s-.4.3-1.3.3c-.3,0-.4,0-.6.1-.2.1-.3.2-.4.3-.1.1-.1.6-.1,1.4v15.6c0,.9.1,1.5.1,1.6.2.2.8.3,1.7.3.7-.3.8-.2.8-.1h0Z"
                                                    fill="#231f20" stroke-width="0" />
                                                <path
                                                    d="M206.31,65.78c0,.2-.2.5-.5,1s-.6.9-.9,1.2c-.9.9-2,1.3-3.4,1.3-1.8,0-3.3-.6-4.5-1.9-1.2-1.3-1.8-2.8-1.8-4.7s.6-3.6,1.7-4.9c1.1-1.3,2.6-2,4.3-2,1.4,0,2.6.4,3.6,1.2s1.5,1.8,1.5,3.1c-.6.1-1.5.1-2.6.1h-2.1c-.1,0-1.3,0-3.6.1-.1.6-.1,1.3-.1,1.8,0,2,.4,3.5,1.1,4.5.8,1.1,1.9,1.6,3.2,1.6.8,0,1.4-.2,1.9-.5.5-.3,1-1,1.6-2,.1-.2.2-.2.3-.2.2.1.3.2.3.3h0ZM203.71,59.78c-.1-2.4-.9-3.5-2.6-3.5s-2.6,1.2-3,3.5h5.6Z"
                                                    fill="#231f20" stroke-width="0" />
                                                <path
                                                    d="M219.31,67.68c0,.3-.4.7-1.1,1s-1.4.5-2,.5c-1.4,0-2.1-.7-2.1-2.1-.8,1.5-1.9,2.2-3.5,2.2-.9,0-1.6-.2-2.1-.8s-.8-1.1-.8-1.9c0-1.6,1-2.9,2.9-3.8.7-.3,1.8-.6,3.5-.9v-.7c0-1.7-.1-2.9-.3-3.4-.3-1-1-1.5-2.2-1.5-.7,0-1.3.2-1.8.6s-.7.8-.7,1.4c0,.2.1.2.4.2s.5.1.7.3.3.5.3.8c0,.9-.4,1.3-1.3,1.3-.5,0-.8-.1-1.1-.5-.3-.3-.4-.7-.4-1.2,0-.9.4-1.7,1.1-2.4.8-.7,2-1.1,3.4-1.1,2.2,0,3.5.6,4,1.8.2.5.3,1.3.3,2.7v7.1c.1.7.4,1.1.9,1.1.6,0,1-.2,1.4-.6.2-.2.4-.3.4-.3t.1.2h0ZM214.01,64.58v-2.1c-1.5.3-2.5.8-3.2,1.3s-1,1.3-1,2.2c0,.6.2,1.1.6,1.5.4.4.8.6,1.4.6.9,0,1.5-.5,2-1.4.1-.3.2-1,.2-2.1h0Z"
                                                    fill="#231f20" stroke-width="0" />
                                                <path
                                                    d="M224.41,68.78c0,.2-.1.3-.4.3h-.4s-.6,0-1.6-.1h-3.7c-.3,0-.4-.1-.4-.2s.1-.2.3-.2h.8c.6,0,.8-.1.8-.4v-17.2c0-.6,0-1-.1-1.1-.1-.2-.2-.2-.5-.2h-1.1c-.3,0-.4-.1-.4-.2s.2-.2.8-.2c1-.1,1.9-.3,2.7-.6l.6-.3.2-.1c.2.6.2,1.4.2,2.6v17.5c0,.1.1.2.2.2,0,0,.4,0,1.1.1.7-.1.9-.1.9.1h0Z"
                                                    fill="#231f20" stroke-width="0" />
                                                <path
                                                    d="M233.11,67.28c0,.2-.2.5-.8.9-.9.8-1.9,1.2-3.1,1.2-1.1,0-1.9-.3-2.5-.8-.3-.3-.5-1.2-.5-2.8v-8.7c0-.2-.1-.3-.3-.3h-1.1c-.4,0-.7-.1-.7-.2s.1-.2.3-.2c1.6-.1,2.8-.9,3.6-2.3.1-.2.3-.3.4-.3s.2.1.2.2v.6c0,.8,0,1.4.1,1.6.2,0,.5.1.9.1,1.1,0,1.8,0,2.2-.2h.2c.2,0,.2.1.2.2,0,.3-.8.4-2.4.4h-1.2v5.4c0,2.7.1,4.5.2,5.2.1.7.6,1,1.4,1,.7,0,1.4-.4,2.2-1.1.1-.1.2-.1.3-.1.3,0,.4.1.4.2h0Z"
                                                    fill="#231f20" stroke-width="0" />
                                                <path
                                                    d="M246.11,68.78c0,.2-.2.2-.5.2h-5.4c-.3,0-.4-.1-.4-.2s.4-.2,1.1-.2c.5,0,.7-.1.8-.2s.1-.5.1-1.1v-6c0-1.8-.1-2.9-.2-3.3-.3-.9-1.1-1.4-2.2-1.4-1,0-1.7.4-2.3,1.3-.2.3-.3.9-.3,1.8v8.4c0,.2.1.3.2.3s.6,0,1.3.1c.2,0,.4.1.4.2s-.1.2-.3.2h-5.8c-.2,0-.3-.1-.3-.2s.1-.2.3-.2h.8c.5,0,.7-.1.7-.4v-13.1c0-3.1-.1-4.8-.1-5.1-.1-.2-.6-.3-1.5-.3-.5,0-.7-.1-.7-.2s.1-.2.1-.2c.1,0,.4,0,1-.1,1,0,2-.2,2.9-.7.1,0,.2-.1.4-.1.1.2.2,1,.2,2.3v7.2c.9-1.2,2.1-1.9,3.6-1.9,1.6,0,2.7.4,3.4,1.3.3.4.6,1,.6,1.8,0,.3.1.9.1,2v7.4c0,.2.5.3,1.6.3.3,0,.4,0,.4.1h0Z"
                                                    fill="#231f20" stroke-width="0" />
                                            </g>
                                        </svg>

                                    </div> -->
                                </div>
                            </div>
                        </AccordionSection>
                    </section>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import HeaderSection from '@/components/HeaderSection.vue';
    import AccordionSection from '@/components/AccordionSection.vue';
export default {
    name: 'BaptistHealth',
    components: {
        HeaderSection,
        AccordionSection
    },
    data() {
        return {
            sectionTitle: "Logos",
            headerColor: "u-textBrandAlt",
        };
    },
    mounted() {
        // console.log('Logos component mounted');
    },
};
</script>