<template>
    <section class="Band-content u-bgWhite u-padTop8 u-textBlack" id="typography">
        <div class="u-padSides8">
            <div class="u-containSpread">

                <HeaderSection :sectionTitle="sectionTitle" :headerColor="headerColor" />
                <p>
                    Consistent typography is essential to a company's brand identity, contributing to a unified brand
                    experience across all platforms and materials. Baptist Health has carefully selected its typography
                    to boost readability and strengthen brand recognition.
                </p>
                <h2 class="u-textBlackAlt u-padTop8">Web/Digital</h2>
                <p>
                    Noto Sans is the primary and most frequently used typeface.
                </p>
                <div class="Grid Grid--withGap u-padTop3">
                    <div class="Grid-cell u-sizefull">
                        <div>
                            <h5 class="notosans u-textSize1">
                                Noto Sans
                            </h5>
                            <hr />
                        </div>
                    </div>
                    <div class="Grid-cell u-sizefull">
                        <div class="u-spaceBottom8">
                            <p class="notosans">
                                ABCDEFGHIJKLMNOPQRSTUVWXYZ<br>
                                abcdefghijklmnopqrstuvwxyz<br>
                                1234567890!@#$%^&*()?{}”'
                            </p>
                            <small class="u-textGray">(Available in 400 and 700 weights)</small>
                        </div>
                    </div>
                    <!-- <hr /> -->
                </div>

                <h2 class="u-textBlackAlt u-padTop4">Print</h2>
                <p>
                    Use the Alright Sans type family for headlines and accent type, such as pull quotes. Numbers in
                    Alright Sans should be capitalized to align correctly. The weight bold should be used for all
                    headlines and subheads.
                </p>
                <div class="Grid Grid--withGap u-padTop2">
                    <div class="Grid-cell u-sizefull">
                        <div>
                            <h5 class="alrightsans u-textSize1">
                                Alright Sans
                            </h5>
                            <hr />
                        </div>
                    </div>
                    <div class="Grid-cell u-sizefull">
                        <div class="u-spaceBottom8">
                            <p class="alrightsans">
                                ABCDEFGHIJKLMNOPQRSTUVWXYZ<br>
                                abcdefghijklmnopqrstuvwxyz<br>
                                1234567890!@#$%^&*()?{}”'
                            </p>
                            <small class="u-textGray">(Available in Light, Light Italic, Regular, Regular Italic,
                                Medium, Medium Italic, Bold and Bold Italic weights)</small>
                        </div>
                    </div>
                </div>
                <!-- <hr /> -->
                <p>
                    Use the Avenir type family for body copy in advertising and corporate communications.
                </p>
                <div class="Grid Grid--withGap u-padTop2">
                    <div class="Grid-cell u-sizefull">
                        <div>
                            <h5 class="avenir u-textSize1">
                                Avenir
                            </h5>
                            <hr />
                        </div>
                    </div>
                    <div class="Grid-cell u-sizefull">
                        <div class="u-spaceBottom8">
                            <p class="avenir">
                                ABCDEFGHIJKLMNOPQRSTUVWXYZ<br>
                                abcdefghijklmnopqrstuvwxyz<br>
                                1234567890!@#$%^&*()?{}”'
                            </p>
                            <small class="u-textGray">(Available in Light, Light Oblique, Book, Book Oblique, Roman,
                                Oblique, Medium, Medium Oblique, Black, Black Oblique, Heavy and Heavy Oblique
                                weights.)</small>
                        </div>
                    </div>
                </div>

                <h2 class="u-textBlackAlt u-padTop4">Signage</h2>
                <p>
                    MetaBold fonts are used on signs and other large-formats.
                </p>
                <div class="Grid Grid--withGap u-padTop2">
                    <div class="Grid-cell u-sizefull">
                        <div>
                            <h5 class="metaboldroman u-textSize1">
                                Meta Bold Roman
                            </h5>
                            <hr />
                        </div>
                    </div>
                    <div class="Grid-cell u-sizefull">
                        <div class="u-spaceBottom8">
                            <p class="metaboldroman">
                                ABCDEFGHIJKLMNOPQRSTUVWXYZ<br>
                                abcdefghijklmnopqrstuvwxyz<br>
                                1234567890!@#$%^&*()?{}”'
                            </p>
                            <small class="u-textGray">(Meta Bold LF-Roman for numbers)</small>
                        </div>
                    </div>
                </div>

                <h3 class="u-textBlackAlt u-padTop4">Leading</h3>
                <p>
                    Line spacing, or leading, is a crucial design element that affects the vertical spacing between
                    lines of text. It's important to adjust the spacing so that there is sufficient distance from the
                    bottom of the words in one line to the top of the words in the next, as well as ensuring legibility.
                    For example, use 15 pt leading for 10 pt type and 16 pt leading for 11 pt type.
                </p>
                <div class="Grid Grid--withGap u-textCenter u-padTop2">
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3">
                        <div class="u-pad2 u-bgSilver u-textBlack leadingEx1">Loose leading creates<br />too much pause
                            for the reader.
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3">
                        <div class="u-pad2 u-bgSilver u-textBlack leadingEx2">Tight leading creates<br />too little
                            pause for the reader.
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3">
                        <div class="u-pad2 u-bgSilver u-textBlack leadingEx3">Correct leading gives the reader<br />the
                            right amount of pause.</div>
                    </div>
                </div>

                <h3 class="u-textBlackAlt u-padTop8">Kerning</h3>
                <p>
                    Letterspacing, also known as kerning, fine-tunes the space between individual letters. When letters
                    are too close, words become difficult to read; they are too far apart, and they appear disjointed.
                    Baptist Health prefers a kerning setting of -10 for all body copy and headlines to optimize
                    readability.
                </p>
                <div class="Grid Grid--withGap u-textCenter u-padTop2">
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3 kerning">
                        <div class="u-pad1 u-bgSilver u-textBlack kerningEx1">Kerning that is too loose leaves too much
                            space between the letters.
                        </div>
                    </div>
                    <div class="Grid-cell textExampleBox u-sm-sizefull u-lg-size1of3">
                        <div class="u-pad1 u-bgSilver u-textBlack kerningEx2">Kerning that is too tight is hard to read.
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3">
                        <div class="u-pad1 u-bgSilver u-textBlack kerningEx3">Kerning set at -10 is easiest for the
                            reader.</div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
    import HeaderSection from '@/components/HeaderSection.vue';
    export default {
        name: 'WolfsonChildrens',
        components: {
            HeaderSection,
        },
        data() {
            return {
                sectionTitle: "Typography",
                headerColor: "u-textBlue",
            };
        },
        mounted() {
            // console.log('Typography component mounted');
        },
    };
</script>