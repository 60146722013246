<template>
    <section class="Band-content u-bgWhite u-padTop8 u-textBlack" id="color">
        <div class="u-padSides8">
            <div class="u-containSpread">
                <HeaderSection :sectionTitle="sectionTitle" :headerColor="headerColor" />
                <p>
                    Color helps set the brand apart. This is the primary color scheme for Baptist MD Anderson.
                </p>
                <h2 class="u-textBlackAlt u-padTop2">Brand Core</h2>
                <p></p>
                <div class="Grid Grid--withGap">
                    <div class="Grid-cell u-sizefull">
                        <div class="swatch-lg u-bgBMDABlue u-textWhite u-pad2 u-sm-pad1 u-md-pad8">
                            <h3>Brand Blue</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#005AA7</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">100-68-1-2</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">0-91-168</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3">
                        <div class="swatch-lg u-bgBMDARed u-textWhite u-pad2 u-sm-pad1 u-md-pad8">
                            <h3>Red</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#EE3124</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">0-95-100-0</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">164-215-244</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3">
                        <div class="swatch-lg u-bgBMDAGray u-textWhite u-pad2 u-sm-pad1 u-md-pad8">
                            <h3>Gray</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#4E4F53</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">67-59-53-34</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">78-79-83</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3">
                        <div class="swatch-lg u-bgBMDABlack u-textWhite u-pad2 u-sm-pad1 u-md-pad8">
                            <h3>Black</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size2of8">#231F20</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">0-0-0-100</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">35-31-32</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';
export default {
    name: 'BMDA',
    components: {
        HeaderSection,
    },
    data() {
        return {
            sectionTitle: "Color",
            headerColor: "u-textBMDA",
        };
    },
    mounted() {
        // console.log('Color component mounted');
    }
};
</script>