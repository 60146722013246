<template>
    <section class="Band-content u-bgWhite u-padTop8">
        <div class="u-padSides8">
            <div class="u-containSpread">
                <AccordionSection title="Questions?">
                    <div class="Grid-cell u-sizefull">
                        <p>
                            If you have any questions regarding logos or any marketing materials, contact <a
                                href="mailto:Cynthia.Klusmeyer@bmcjax.com"><strong>Cynthia
                            Klusmeyer</strong></a>, <em>Creative Services Manager</em> or <a href="mailto:Creative.Services@bmcjax.com"><strong>Creative Services</strong></a>.
                        </p>
                        <!-- <form @submit.prevent="submitForm">
                            <div class="Grid form-group u-spaceItems04">
                                <div class="Grid Grid--withGap u-padTop8 u-md-padLeft12">

                                    <div class="Grid-cell u-size1of2">
                                        <label for="name" class="u-textBlackAlt">Name <span
                                                class="u-hiddenVisually">(required)</span><sup
                                                class="u-textRed">*</sup></label>
                                        <input v-model="name" class="required u-sizeFull" id="name" name="name"
                                            type="text" minlength="2" maxlength="128" autocomplete="name" required>
                                    </div>
                                    <div class="Grid-cell u-size1of2">
                                        <label for="email" class="u-textBlackAlt">Email Address <span
                                                class="u-hiddenVisually">(required)</span><sup
                                                class="u-textRed">*</sup></label>
                                        <input v-model="email" class="u-sizeFull" name=" email" id="email" type="email"
                                            maxlength="128" minlength="2" autocomplete="email" required>
                                    </div>
                                    <div class="Grid-cell u-sizeFull">
                                        <div>
                                            <label for="message" class="u-textBlackAlt">Message<span
                                                    class="u-hiddenVisually">(required)</span><sup
                                                    class="u-textRed">*</sup></label>
                                            <textarea v-model="message" class="u-pad1 u-sizeFull" name="message"
                                                id="message" title="Message" minlength="2" maxlength="1000"
                                                autocomplete="off" required></textarea>
                                        </div>
                                    </div>
                                    <div class="Grid-cell u-sizeFull">
                                        <div class="">
                                            <input type="submit" id="btnContact" class="Button Button--tertiary u-textSize1 u-textCenter u-sizeFull
                u-borderColorBlackAlt u-textBlackAlt u-textUpper" value="Send Message">
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </form> -->
                    </div>
                </AccordionSection>
            </div>
        </div>
    </section>

</template>

<script>
// import Axios from 'axios';
import AccordionSection from '@/components/AccordionSection.vue';

export default {
    components: {
        AccordionSection,
    },
    // data() {
    //     return {
    //         givenname: '',
    //         email: '',
    //         message: '',
    //     };
    // },
    // methods: {
    //     async submitForm() {
    //         const formData = {
    //             givenname: this.givenname,
    //             email: this.email,
    //             message: this.message,
    //         };

    //         try {
    //             // create form on FormAssembly and update with actual endpoint
    //             const response = await Axios.post('https://baptisthealth.tfaforms.net/rest/forms/view/155', formData);
    //             console.log('Form submitted:', response.data);
    //         } catch (error) {
    //             console.error('Error submitting form:', error);

    //         }
    //     }
    // }
};
</script>
