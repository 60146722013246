<template>
    <section class="Band-content u-bgWhite u-padTop8 u-textBlack" id="color">
        <div class="u-padSides8">
            <div class="u-containSpread">

                <HeaderSection :sectionTitle="sectionTitle" :headerColor="headerColor" />
                <p>
                    Color helps set the brand apart. This is the primary color scheme.
                </p>
                <h2 class="u-textBlackAlt u-padTop2">Brand Core</h2>
                <p>
                    Wolfson Children's signature blue leads their color palette, complemented by green, yellow, and red.
                    These colors, reminiscent of a crayon box's primary hues, evoke the innocence and vibrant energy of
                    children.
                </p><br />
                <div class="Grid Grid--withGap">
                    <div class="Grid-cell u-sm-sizefull u-lg-size2of3">
                        <div class="swatch-lg u-bgWolfsonBlue u-textWhite u-pad2 u-sm-pad1 u-md-pad8">
                            <h3>Signature Blue</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#00ADEF</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">100-0-0-0</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">0-174-239</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3">
                        <div class="swatch-lg u-bgWolfsonGreen u-textWhite u-pad2 u-sm-pad1 u-md-pad8">
                            <h3>Green</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#008f7D</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">100-0-56-18</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">0-144-126</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3">
                        <div class="swatch-lg u-bgWolfsonRed u-textWhite u-pad2 u-sm-pad1 u-md-pad8">
                            <h3>Red</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#E21836</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">0-100-8-4</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">227-24-55</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3">
                        <div class="swatch-lg u-bgWolfsonYellow u-textBlackAlt u-pad2 u-sm-pad1 u-md-pad8">
                            <h3>Yellow</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#FFD559</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">0-15-76-0</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">255-214-90</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3">
                        <div class="swatch-lg u-bgBMDABlack u-textWhite u-pad2 u-sm-pad1 u-md-pad8">
                            <h3>Black</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size2of8">#231F20</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">0-0-0-100</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">35-31-32</div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </section>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';
export default {
    name: 'WolfsonChildrens',
    components: {
        HeaderSection,
    },
    data() {
        return {
            sectionTitle: "Color",
            headerColor: "u-textBlue",
        };
    },
    mounted() {
        // console.log('Color component mounted');
    }
};
</script>