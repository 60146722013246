<template>
  <div id="toggleWrapper">
    <menu class="toggle">
      <input type="radio" id="baptist" value="baptist" class="sliding-toggle-option baptist"
             v-model="selectedToggle" @change="navigate('baptist')" checked>
      <label for="baptist">Baptist<br />Health</label>

      <input type="radio" id="wolfson" value="wolfson" class="sliding-toggle-option wolfson"
             v-model="selectedToggle" @change="navigate('wolfson')">
      <label for="wolfson">Wolfson<br />Children's</label>

      <input type="radio" id="bmda" value="bmda" class="sliding-toggle-option bmda"
             v-model="selectedToggle" @change="navigate('bmda')">
      <label for="bmda">Baptist<br />MD Anderson</label>

      <div class="selector">&nbsp;</div>
    </menu>
  </div>
  <router-view/>
</template>

<script>
export default {
  data() {
    return {
      selectedToggle: '', // Leave this empty to be set by created lifecycle hook
    };
  },
  methods: {
    navigate(routeValue) {
      if (this.$route.path !== `/${routeValue}`) {
        this.$router.push(`/${routeValue}`);
      }
      this.changeBodyBackgroundColor(`/${routeValue}`);
    },
    changeBodyBackgroundColor(path) {
      let color;
      switch (path) {
        case '/baptist':
          color = '#364958';
          break;
        case '/wolfson':
          color = '#008fc4';
          break;
        case '/bmda':
          color = '#e8eef2';
          break;
        default:
          color = '#364958'; // default color
          break;
      }
      document.body.style.backgroundColor = color;
    }
  },
  watch: {
    $route(to) {
    const rootRoute = to.path.split('/')[1];
    this.selectedToggle = rootRoute; // Make sure this updates correctly
    this.changeBodyBackgroundColor('/' + rootRoute);
}
  },
  created() {
    // Extract the first segment of the current route path.
    const rootRoute = this.$route.path.split('/')[1];

    console.log("Initial route segment:", rootRoute);  // Keep this log to observe what's captured upon load.

    if (['baptist', 'wolfson', 'bmda'].includes(rootRoute)) {
      // If the root route is one of the recognized sections, set it and adjust background color accordingly.
      this.selectedToggle = rootRoute;
      this.changeBodyBackgroundColor(`/${rootRoute}`);
    } else {
      // If no valid root route is found, only then default to 'baptist'.
      // Check if the current path is already '/baptist' to avoid unnecessary navigation.
      if (this.$route.path !== '/baptist') {
        this.changeBodyBackgroundColor('/');
      }
    }
  }



};
</script>
