<template>
    <WelcomeBarSection @toggleWelcomeBar="adjustWrapperPadding" />
    <div class="Band" :style="{ paddingTop: wrapperPadding }" id="wrapper">

        <NavSection basePath="/baptist" />

        <!-- HeaderSection Begin -->
        <section class="Band-content u-padTop1 u-padBottom8" @click="resetHeaderVisibility">
            <div class="u-padSides8">
                <div class="u-containSpread u-textCenter">
                    <svg viewBox="0 0 205.16 40.43" class="bh-header u-textWhite" role="presentation">
                        <polygon
                            points="40.64 15.9 30.06 15.89 30.06 12.94 39.18 12.94 41.53 15.5 42.12 16.11 42.11 26.31 41.08 27.56 41.08 15.91 40.64 15.9"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m33.65,31.58l-2.73-6.63s-3.08-9.88-3.14-10.23h-.48v1.44s-1.35,0-1.35,0v-.93h-8.33V3.66h12.44l-3.64-3.66h-14.43v12.76H0v10.99l4.72,4.71v-12.54s1.72,0,1.72,0l.78,1.15h6.22l1.82,1.45,4.91.45,2.04,4.94-.61,1.69,1.43-1.37.73.72-1.55,2.25,2.83,7.3,4.83,3.6,1.3,2.34,1.26-2.69,1.47-1.94-.26-4.22Z"
                            fill="#fff" stroke-width="0" />
                        <polygon
                            points="17.33 28.7 11.52 28.7 11.51 34.42 17.52 40.42 28.98 40.4 27.73 39.15 17.33 39.15 17.33 28.7"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m69.02,25.32c0,2-.93,3.47-2.79,4.43-.9.47-2.19.76-3.86.88-.46.02-1.82.03-4.07.03h-3.65c-.34,0-.51-.08-.51-.25s.31-.25.93-.25c.72,0,1.14-.07,1.27-.22s.19-.59.19-1.34v-15.88c0-.86-.04-1.36-.12-1.5-.14-.23-.65-.36-1.53-.38-.54,0-.81-.1-.81-.31,0-.19.25-.28.75-.28h.36c.22.06.42.09.6.09h5.84c2.16.02,3.76.37,4.82,1.06,1.24.8,1.86,1.97,1.86,3.49,0,1.33-.51,2.42-1.53,3.27-1.02.85-2.45,1.39-4.28,1.6v.18c1.82.12,3.23.49,4.25,1.12,1.52.94,2.28,2.36,2.28,4.26Zm-4.01-10.13c0-1.88-.44-3.13-1.32-3.76-.46-.33-1.33-.5-2.61-.5-.34,0-.87.03-1.59.09v8.51h.27l1.65-.06c1.14-.04,2.02-.44,2.65-1.19s.94-1.79.94-3.1Zm.87,9.81c0-2.47-1.02-3.99-3.05-4.58-.6-.18-1.71-.26-3.32-.26v8.75c0,.57.06.89.18.97.2.14.76.21,1.68.21,1.38,0,2.48-.43,3.29-1.29.82-.86,1.23-2.12,1.23-3.79Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m82.58,29.34c0,.33-.35.68-1.06,1.04-.71.36-1.39.54-2.04.54-1.37,0-2.09-.69-2.15-2.08-.75,1.45-1.93,2.17-3.52,2.17-.85,0-1.56-.25-2.12-.75s-.83-1.14-.83-1.92c0-1.6.98-2.88,2.94-3.82.67-.31,1.84-.63,3.5-.94v-.73c0-1.72-.09-2.86-.27-3.41-.32-1-1.04-1.5-2.16-1.5-.7,0-1.28.19-1.75.56-.47.37-.7.84-.7,1.41,0,.16.12.23.37.23s.47.12.67.35c.2.24.3.5.3.79,0,.86-.44,1.29-1.32,1.29-.46,0-.84-.15-1.14-.46-.3-.3-.45-.69-.45-1.16,0-.92.37-1.71,1.11-2.38.82-.72,1.96-1.09,3.41-1.09,2.2,0,3.54.59,4.04,1.76.18.45.27,1.34.27,2.67v5.49c0,.9,0,1.43.03,1.58.1.71.39,1.06.87,1.06.56,0,1.02-.2,1.38-.59.2-.21.35-.32.45-.32s.15.06.15.18Zm-5.27-3.11v-2.14c-1.48.33-2.53.77-3.17,1.31-.64.54-.96,1.26-.96,2.16,0,.63.18,1.14.55,1.54.36.4.82.6,1.37.6.85,0,1.52-.47,2.04-1.41.12-.21.18-.9.18-2.05Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m94.71,24.14c0,1.96-.57,3.59-1.7,4.9-1.13,1.31-2.54,1.97-4.22,1.97-1.32,0-2.34-.47-3.06-1.41v6.84c0,.43.02.69.05.76.03.08.13.15.29.21.3.06.89.09,1.76.09.24,0,.36.09.36.27,0,.14-.13.21-.39.21h-5.87c-.58,0-.87-.07-.87-.22,0-.19.12-.28.36-.28h.9c.58,0,.89-.13.93-.38.02-.12.03-1.02.03-2.7v-14.62c0-.55-.27-.8-.81-.76h-1.26c-.18,0-.27-.07-.27-.22,0-.19.22-.28.66-.28,1.1.02,2.1-.24,2.99-.76.26-.16.46-.23.6-.23.16,0,.26.07.31.22s.08.59.1,1.34c.74-1.04,1.8-1.56,3.17-1.56,1.74,0,3.16.62,4.27,1.86,1.11,1.24,1.66,2.83,1.66,4.77Zm-2.67.01c0-1.53-.24-2.83-.72-3.91-.64-1.49-1.64-2.24-2.98-2.24-.7,0-1.3.24-1.81.72-.5.48-.77,1.06-.81,1.75-.04.86-.03,2.68.03,5.44.02,1.39.05,2.23.09,2.52.04.28.17.57.39.87.58.8,1.33,1.21,2.26,1.21,1.04,0,1.83-.43,2.35-1.29.8-1.31,1.2-3,1.2-5.06Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m104.03,28.93c0,.18-.25.49-.75.94-.86.76-1.88,1.15-3.05,1.15-1.1,0-1.93-.27-2.49-.82-.32-.31-.48-1.23-.48-2.75v-8.73c-.02-.2-.13-.3-.33-.32h-1.14c-.44,0-.66-.07-.66-.21s.11-.22.33-.23c1.55-.14,2.76-.89,3.63-2.26.12-.18.27-.27.45-.27.14,0,.21.07.21.21v.62c0,.82.04,1.35.12,1.59.18.04.48.06.9.06,1.1,0,1.83-.05,2.19-.15.08-.02.15-.03.21-.03.16,0,.24.08.24.25,0,.28-.81.42-2.43.42h-1.23v5.43c0,2.74.07,4.46.21,5.15.14.7.6,1.04,1.38,1.04.66,0,1.4-.36,2.22-1.09.08-.1.17-.15.27-.15.14,0,.21.05.21.15Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m110.04,30.48c0,.18-.17.26-.51.26.02,0-.22-.02-.72-.06-.6-.04-1.1-.06-1.5-.06h-3.11c-.1.08-.22.12-.36.12-.22,0-.33-.07-.33-.22,0-.21.2-.31.6-.31.76-.02,1.17-.07,1.24-.16.07-.09.1-.49.1-1.22v-8.9c-.02-.49-.04-.78-.07-.87-.03-.09-.16-.13-.37-.13h-1.17c-.22,0-.33-.07-.33-.21,0-.12.17-.19.5-.21,1.32-.06,2.45-.36,3.4-.92l.3-.17c.16.29.24.83.24,1.61,0-.04,0,.06,0,.29v9.14c0,.78.01,1.24.04,1.38.03.14.13.22.31.26.3.08.74.12,1.32.12.28,0,.42.08.42.23Zm-2.25-18.29c0,.88-.45,1.32-1.35,1.32-.38,0-.7-.12-.96-.37-.26-.24-.39-.54-.39-.9,0-.92.45-1.38,1.35-1.38s1.35.44,1.35,1.32Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m119.72,26.92c0,1.14-.42,2.1-1.26,2.9-.84.79-1.85,1.19-3.02,1.19-.67,0-1.5-.24-2.47-.71-.26-.14-.49-.21-.68-.21-.28,0-.49.19-.62.56-.08.23-.19.35-.33.35s-.21-.08-.21-.24v-3.93c0-.22.07-.32.21-.32.16,0,.25.11.27.33.12.91.38,1.62.78,2.13.8,1.03,1.87,1.54,3.2,1.54.72,0,1.3-.21,1.74-.62.44-.41.66-.96.66-1.65,0-.76-.25-1.37-.75-1.82-.28-.25-.53-.44-.76-.54-.23-.11-.9-.37-2.02-.78-2.22-.82-3.32-2.06-3.32-3.71,0-1.1.38-2.02,1.13-2.77.75-.75,1.68-1.12,2.79-1.12.4,0,.99.12,1.78.35.2.06.38.09.54.09.42,0,.67-.2.77-.59.06-.2.15-.29.27-.29.14,0,.21.08.21.23v1.41c-.02.47.03,1.22.15,2.26v.06c0,.16-.08.23-.24.23-.12,0-.27-.3-.45-.89-.22-.71-.6-1.28-1.15-1.72-.55-.43-1.18-.65-1.9-.65-.68,0-1.24.21-1.68.62s-.66.93-.66,1.56c0,.98.67,1.73,2.01,2.24,2.08.8,3.39,1.47,3.95,2,.72.69,1.08,1.52,1.08,2.5Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m128.91,28.93c0,.18-.25.49-.75.94-.86.76-1.88,1.15-3.05,1.15-1.1,0-1.93-.27-2.49-.82-.32-.31-.48-1.23-.48-2.75v-8.73c-.02-.2-.13-.3-.33-.32h-1.14c-.44,0-.66-.07-.66-.21s.11-.22.33-.23c1.55-.14,2.76-.89,3.63-2.26.12-.18.27-.27.45-.27.14,0,.21.07.21.21v.62c0,.82.04,1.35.12,1.59.18.04.48.06.9.06,1.1,0,1.83-.05,2.19-.15.08-.02.15-.03.21-.03.16,0,.24.08.24.25,0,.28-.81.42-2.43.42h-1.23v5.43c0,2.74.07,4.46.21,5.15.14.7.6,1.04,1.38,1.04.66,0,1.4-.36,2.22-1.09.08-.1.17-.15.27-.15.14,0,.21.05.21.15Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m153.05,30.4c0,.18-.19.26-.57.26h-6.51c-.4,0-.59-.08-.59-.23s.46-.26,1.39-.32c.49-.04.78-.12.86-.25.08-.13.12-.58.12-1.36v-8.55h-9.01v8.31c0,.88.07,1.4.21,1.56.14.16.61.26,1.41.32.52.04.78.13.78.26,0,.18-.2.26-.6.26h-6.5c-.4,0-.6-.08-.6-.23s.2-.25.6-.29c.26-.02.53-.04.81-.06.44-.04.72-.14.84-.29.06-.1.09-.64.09-1.64v-15.48c0-.88-.04-1.4-.13-1.55-.09-.16-.4-.24-.94-.26-.62-.04-.98-.07-1.09-.09-.11-.02-.16-.1-.16-.25,0-.16.13-.25.39-.25.08,0,.25,0,.51.03.5.04,1.68.06,3.53.06,1.02,0,1.73-.01,2.13-.04.14-.03.32-.04.54-.04.36,0,.54.1.54.29,0,.18-.33.27-.97.27s-1.04.07-1.17.22c-.13.15-.19.61-.19,1.4v6.78h9.01v-6.33c0-1.04-.08-1.65-.24-1.84s-.72-.27-1.68-.25c-.3,0-.45-.09-.45-.27s.17-.27.51-.27h6.53c.42,0,.63.09.63.26s-.44.27-1.32.29c-.26,0-.45.02-.57.06-.2.06-.33.17-.38.32s-.07.62-.07,1.38v15.62c0,.94.05,1.49.15,1.64.2.22.77.32,1.71.32.32-.04.47.04.47.23Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m165.36,27.46c0,.2-.16.54-.46,1.04s-.62.91-.94,1.22c-.86.86-2.01,1.29-3.44,1.29-1.8,0-3.3-.64-4.51-1.91-1.21-1.27-1.81-2.85-1.81-4.73s.57-3.59,1.72-4.9c1.15-1.31,2.58-1.97,4.3-1.97,1.42,0,2.61.41,3.56,1.22.96.81,1.47,1.85,1.53,3.13-.64.1-1.49.14-2.55.12h-2.1c-.14,0-1.34.03-3.59.09-.1.64-.15,1.26-.15,1.84,0,1.97.38,3.49,1.15,4.55.77,1.06,1.85,1.59,3.25,1.59.8,0,1.44-.18,1.92-.53s1-1.03,1.56-2.03c.08-.16.19-.23.33-.23.16,0,.24.07.24.21Zm-2.61-5.99c-.08-2.35-.94-3.52-2.58-3.52s-2.63,1.17-3.02,3.52h5.6Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m178.32,29.34c0,.33-.35.68-1.06,1.04s-1.39.54-2.04.54c-1.37,0-2.09-.69-2.15-2.08-.75,1.45-1.93,2.17-3.52,2.17-.85,0-1.56-.25-2.12-.75-.56-.5-.83-1.14-.83-1.92,0-1.6.98-2.88,2.94-3.82.67-.31,1.84-.63,3.5-.94v-.73c0-1.72-.09-2.86-.27-3.41-.32-1-1.04-1.5-2.16-1.5-.7,0-1.28.19-1.75.56-.47.37-.7.84-.7,1.41,0,.16.12.23.37.23s.47.12.67.35c.2.24.3.5.3.79,0,.86-.44,1.29-1.32,1.29-.46,0-.84-.15-1.14-.46-.3-.3-.45-.69-.45-1.16,0-.92.37-1.71,1.11-2.38.82-.72,1.96-1.09,3.41-1.09,2.2,0,3.54.59,4.04,1.76.18.45.27,1.34.27,2.67v5.49c0,.9,0,1.43.03,1.58.1.71.39,1.06.87,1.06.56,0,1.02-.2,1.38-.59.2-.21.35-.32.45-.32s.15.06.15.18Zm-5.27-3.11v-2.14c-1.48.33-2.53.77-3.17,1.31-.64.54-.96,1.26-.96,2.16,0,.63.18,1.14.55,1.54.36.4.82.6,1.37.6.85,0,1.52-.47,2.04-1.41.12-.21.18-.9.18-2.05Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m183.48,30.45c0,.18-.13.26-.39.26h-.36s-.57-.02-1.59-.06h-.93c-.16,0-.96.01-2.4.04-.12,0-.24.01-.36.01-.3.02-.45-.06-.45-.25,0-.13.11-.19.33-.19h.78c.56,0,.84-.12.84-.35.02-.12.03-.88.03-2.29v-14.94c0-.65-.02-1-.06-1.06-.06-.16-.22-.23-.48-.23h-1.08c-.28,0-.42-.08-.42-.25,0-.15.25-.23.75-.25.96-.06,1.85-.27,2.67-.65l.63-.29.24-.09c.16.57.23,1.43.21,2.58v16.47c0,.59,0,.93.03,1.04.02.11.09.18.21.22.02.02.39.04,1.11.06.46,0,.69.07.69.21Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m192.16,28.93c0,.18-.25.49-.75.94-.86.76-1.88,1.15-3.05,1.15-1.1,0-1.93-.27-2.49-.82-.32-.31-.48-1.23-.48-2.75v-8.73c-.02-.2-.13-.3-.33-.32h-1.14c-.44,0-.66-.07-.66-.21s.11-.22.33-.23c1.55-.14,2.76-.89,3.63-2.26.12-.18.27-.27.45-.27.14,0,.21.07.21.21v.62c0,.82.04,1.35.12,1.59.18.04.48.06.9.06,1.1,0,1.83-.05,2.19-.15.08-.02.15-.03.21-.03.16,0,.24.08.24.25,0,.28-.81.42-2.43.42h-1.23v5.43c0,2.74.07,4.46.21,5.15.14.7.6,1.04,1.38,1.04.66,0,1.4-.36,2.22-1.09.08-.1.17-.15.27-.15.14,0,.21.05.21.15Z"
                            fill="#fff" stroke-width="0" />
                        <path
                            d="m205.16,30.48c0,.16-.17.24-.51.24h-5.45c-.26,0-.39-.08-.39-.23,0-.14.38-.21,1.14-.21.46,0,.72-.05.79-.16.07-.11.11-.47.11-1.1v-5.95c0-1.78-.08-2.88-.24-3.31-.34-.9-1.09-1.35-2.25-1.35-.96,0-1.74.42-2.34,1.26-.2.27-.3.88-.3,1.82v7.47c0,.53,0,.83.03.91.02.2.1.31.24.34.14.03.57.05,1.29.07.24,0,.36.08.36.23,0,.14-.1.21-.3.21h-2.67c-1.66-.02-2.58-.02-2.75,0h-.3c-.22,0-.33-.06-.33-.18,0-.14.11-.2.33-.2h.45l.45-.03c.46.02.7-.12.72-.41v-13.2c0-3.14-.05-4.83-.15-5.09-.12-.2-.61-.3-1.47-.32-.48,0-.72-.07-.72-.22,0-.12.05-.2.15-.22.1-.02.44-.04,1.02-.06,1.02-.02,2-.25,2.94-.7.08-.04.22-.09.42-.15.14.23.21.99.21,2.26v7.19c.88-1.25,2.08-1.88,3.59-1.88,1.6,0,2.74.43,3.41,1.29.34.43.55,1.04.63,1.82.04.27.06.93.06,1.97v7.4c.02.2.54.29,1.56.29.18.04.27.11.27.21Z"
                            fill="#fff" stroke-width="0" />
                    </svg>
                    <span class="u-hiddenVisually">Baptist Health</span>
                </div>
            </div>
        </section>

        <section id="quote" class="Band-content u-textWhite" v-if="isHeaderVisible">
            <div class="u-padSides8">
                <div class="u-containSpread">
                    <h2 class="missionQuote u-textCenter u-textSerif">
                        To make hope, healing and well-being accessible to every person as an expression of God's
                        love.
                    </h2>
                </div>
            </div>
        </section>

        <section id="about" class="Band-content u-bgWhite u-padTop8 u-textBlack" v-if="isHeaderVisible">
            <div class="u-padSides8">
                <div class="u-containSpread">
                    <div>
                        <h3 class="intro">
                            At Baptist Health, our commitment to the health of the communities we are honored to serve
                            remains as strong and true today as it was when we were founded nearly 70 years ago. We are
                            a thriving, community-focused, nonprofit health system dedicated to providing top-quality
                            care and services to the people of Northeast Florida and Southern Georgia. Our holistic
                            approach to caring for the physical, mental, spiritual and social needs of our patients
                            guides us as we work each and every day to ensure they live healthier, happier lives.
                        </h3>
                    </div>
                </div>
            </div>
        </section>
        <!-- HeaderSection End -->

        <router-view></router-view>

        <!-- FooterSection Begin -->
        <QuestionSection />
        <section class="Band-content u-bgWhite">
            <div class="u-padSides8">
                <div class="u-containSpread u-textCenter">

                    <div class="Grid Grid--withGap u-textCenter u-padTop2 u-spaceBottom9">
                        <div class="Grid-cell u-sizefull u-padTop9">
                            <div class="u-textBlackAlt">
                                <div class="u-containSpread u-textCenter u-spaceBottom8">
                                    <svg viewBox="0 0 206.8 40.75" class="bh-footer" role="presentation">
                                        <polygon
                                            points="40.96 16.02 30.3 16.02 30.3 13.05 39.5 13.05 41.86 15.62 42.46 16.24 42.45 26.52 41.41 27.78 41.41 16.04 40.96 16.02"
                                            fill="#0056a1" stroke-width="0" />
                                        <path
                                            d="M33.92,31.83l-2.75-6.69s-3.1-9.96-3.16-10.31h-.49v1.45s-1.36,0-1.36,0v-.94h-8.39V3.68h12.54l-3.67-3.68h-14.55v12.86H0v11.08l4.75,4.75v-12.64s1.74,0,1.74,0l.79,1.15h6.27l1.84,1.46,4.95.45,2.06,4.98-.61,1.7,1.44-1.38.73.72-1.56,2.27,2.85,7.35,4.87,3.63,1.31,2.36,1.27-2.71,1.48-1.95-.26-4.25Z"
                                            fill="#0056a1" stroke-width="0" />
                                        <polygon
                                            points="17.46 28.93 11.61 28.93 11.61 34.69 17.66 40.74 29.21 40.73 27.95 39.47 17.47 39.47 17.46 28.93"
                                            fill="#0056a1" stroke-width="0" />
                                        <path
                                            d="M69.57,25.52c0,2.01-.94,3.5-2.81,4.47-.91.47-2.2.77-3.89.89-.46.02-1.83.03-4.11.03h-3.68c-.34,0-.51-.08-.51-.25s.31-.25.94-.25c.72,0,1.15-.07,1.28-.22s.2-.6.2-1.35V12.82c0-.87-.04-1.37-.12-1.51-.14-.24-.65-.36-1.54-.38-.54,0-.82-.1-.82-.31,0-.19.25-.28.75-.28h.36c.22.06.42.09.6.09h5.89c2.17.02,3.79.38,4.86,1.07,1.25.81,1.87,1.98,1.87,3.52,0,1.34-.51,2.44-1.54,3.3-1.03.86-2.47,1.4-4.32,1.61v.18c1.83.12,3.26.49,4.29,1.12,1.53.95,2.29,2.38,2.29,4.29ZM65.52,15.31c0-1.89-.44-3.16-1.33-3.79-.46-.34-1.34-.5-2.63-.5-.34,0-.88.03-1.6.09v8.58h.27l1.66-.06c1.15-.04,2.04-.44,2.67-1.2s.95-1.8.95-3.12ZM66.4,25.19c0-2.49-1.03-4.03-3.08-4.62-.6-.18-1.72-.27-3.35-.27v8.82c0,.57.06.9.18.98.2.14.76.21,1.69.21,1.39,0,2.5-.43,3.32-1.3.82-.87,1.24-2.14,1.24-3.82Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M83.24,29.57c0,.34-.36.69-1.07,1.05-.71.37-1.4.55-2.06.55-1.38,0-2.1-.7-2.16-2.1-.76,1.46-1.94,2.19-3.54,2.19-.86,0-1.57-.25-2.13-.76s-.84-1.15-.84-1.94c0-1.62.99-2.9,2.96-3.85.68-.32,1.85-.63,3.53-.95v-.74c0-1.74-.09-2.88-.27-3.43-.32-1.01-1.05-1.51-2.17-1.51-.7,0-1.29.19-1.77.56-.47.37-.71.85-.71,1.42,0,.16.13.24.38.24s.48.12.68.35c.2.24.3.5.3.8,0,.87-.44,1.3-1.33,1.3-.46,0-.85-.15-1.15-.46-.3-.31-.45-.7-.45-1.17,0-.93.37-1.73,1.12-2.4.82-.73,1.97-1.1,3.44-1.1,2.21,0,3.57.59,4.08,1.78.18.45.27,1.35.27,2.69v5.53c0,.91,0,1.44.03,1.6.1.71.39,1.07.88,1.07.56,0,1.03-.2,1.39-.59.2-.22.35-.33.45-.33s.15.06.15.18ZM77.93,26.44v-2.16c-1.49.34-2.55.77-3.2,1.32-.64.54-.97,1.27-.97,2.18,0,.63.18,1.15.55,1.55.37.4.83.61,1.38.61.85,0,1.54-.47,2.05-1.42.12-.22.18-.91.18-2.07Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M95.47,24.34c0,1.97-.57,3.62-1.71,4.94-1.14,1.32-2.56,1.98-4.26,1.98-1.33,0-2.36-.47-3.09-1.42v6.9c0,.44.02.69.05.77.03.08.13.15.29.21.31.06.9.09,1.77.09.24,0,.37.09.37.27,0,.14-.13.21-.39.21h-5.92c-.58,0-.88-.07-.88-.22,0-.19.12-.28.36-.28h.91c.58,0,.9-.13.94-.38.02-.12.03-1.03.03-2.72v-14.74c0-.55-.27-.81-.82-.77h-1.27c-.18,0-.27-.07-.27-.22,0-.19.22-.28.66-.28,1.11.02,2.11-.24,3.02-.77.26-.16.46-.24.6-.24.16,0,.27.07.32.22s.09.6.11,1.35c.74-1.05,1.81-1.57,3.2-1.57,1.75,0,3.18.63,4.3,1.88,1.12,1.25,1.68,2.86,1.68,4.81ZM92.78,24.35c0-1.54-.24-2.86-.73-3.95-.65-1.5-1.65-2.25-3-2.25-.71,0-1.31.24-1.82.73-.51.48-.78,1.07-.82,1.77-.04.87-.03,2.7.03,5.49.02,1.4.05,2.25.09,2.54.04.29.17.58.39.88.59.81,1.34,1.22,2.27,1.22,1.05,0,1.84-.44,2.37-1.31.81-1.32,1.21-3.03,1.21-5.1Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M104.86,29.16c0,.18-.25.49-.76.95-.87.77-1.89,1.15-3.08,1.15-1.11,0-1.94-.28-2.51-.83-.32-.32-.48-1.24-.48-2.78v-8.8c-.02-.2-.13-.31-.33-.32h-1.15c-.44,0-.66-.07-.66-.21s.11-.22.33-.24c1.56-.14,2.78-.9,3.66-2.28.12-.18.27-.27.45-.27.14,0,.21.07.21.21v.62c0,.83.04,1.36.12,1.6.18.04.48.06.91.06,1.11,0,1.84-.05,2.2-.15.08-.02.15-.03.21-.03.16,0,.24.09.24.26,0,.28-.82.43-2.45.43h-1.24v5.48c0,2.76.07,4.49.21,5.19.14.7.6,1.05,1.39,1.05.66,0,1.41-.36,2.23-1.09.08-.1.17-.15.27-.15.14,0,.21.05.21.15Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M110.92,30.73c0,.18-.17.27-.51.27.02,0-.22-.02-.72-.06-.6-.04-1.11-.06-1.51-.06h-3.14c-.1.08-.22.12-.36.12-.22,0-.33-.07-.33-.22,0-.21.2-.31.6-.31.76-.02,1.18-.07,1.25-.16.07-.09.11-.5.11-1.23v-8.97c-.02-.49-.05-.78-.07-.87-.03-.09-.16-.13-.38-.13h-1.18c-.22,0-.33-.07-.33-.21,0-.12.17-.19.51-.21,1.33-.06,2.47-.37,3.43-.92l.3-.17c.16.3.24.84.24,1.62,0-.04,0,.06,0,.3v9.21c0,.79.01,1.25.05,1.39.03.14.14.23.32.27.3.08.74.12,1.33.12.28,0,.42.08.42.24ZM108.66,12.29c0,.89-.45,1.33-1.36,1.33-.38,0-.7-.12-.97-.37-.26-.25-.39-.55-.39-.9,0-.93.45-1.39,1.36-1.39s1.36.44,1.36,1.33Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M120.68,27.14c0,1.15-.43,2.12-1.27,2.92-.85.8-1.86,1.2-3.04,1.2-.68,0-1.51-.24-2.49-.71-.26-.14-.49-.21-.69-.21-.28,0-.49.19-.63.56-.08.24-.19.36-.33.36s-.21-.08-.21-.24v-3.97c0-.22.07-.33.21-.33.16,0,.25.11.27.33.12.91.38,1.63.79,2.15.8,1.03,1.88,1.55,3.23,1.55.72,0,1.31-.21,1.75-.62.44-.42.66-.97.66-1.66,0-.77-.25-1.38-.75-1.84-.28-.26-.54-.44-.77-.55-.23-.11-.91-.37-2.04-.79-2.23-.83-3.35-2.08-3.35-3.74,0-1.11.38-2.04,1.14-2.79.76-.75,1.7-1.13,2.82-1.13.4,0,1,.12,1.8.36.2.06.38.09.54.09.42,0,.68-.2.78-.59.06-.2.15-.3.27-.3.14,0,.21.08.21.24v1.42c-.02.47.03,1.23.15,2.28v.06c0,.16-.08.24-.24.24-.12,0-.27-.3-.45-.9-.22-.72-.61-1.29-1.16-1.73-.55-.44-1.19-.66-1.92-.66-.68,0-1.25.21-1.69.62s-.66.94-.66,1.57c0,.99.67,1.74,2.02,2.25,2.09.81,3.42,1.48,3.98,2.02.72.69,1.09,1.53,1.09,2.52Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M129.94,29.16c0,.18-.25.49-.76.95-.87.77-1.89,1.15-3.08,1.15-1.11,0-1.94-.28-2.51-.83-.32-.32-.48-1.24-.48-2.78v-8.8c-.02-.2-.13-.31-.33-.32h-1.15c-.44,0-.66-.07-.66-.21s.11-.22.33-.24c1.56-.14,2.78-.9,3.66-2.28.12-.18.27-.27.45-.27.14,0,.21.07.21.21v.62c0,.83.04,1.36.12,1.6.18.04.48.06.91.06,1.11,0,1.84-.05,2.2-.15.08-.02.15-.03.21-.03.16,0,.24.09.24.26,0,.28-.82.43-2.45.43h-1.24v5.48c0,2.76.07,4.49.21,5.19.14.7.6,1.05,1.39,1.05.66,0,1.41-.36,2.23-1.09.08-.1.17-.15.27-.15.14,0,.21.05.21.15Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M154.28,30.64c0,.18-.19.27-.57.27h-6.56c-.4,0-.6-.08-.6-.24s.47-.27,1.4-.32c.5-.04.78-.12.86-.25.08-.13.12-.58.12-1.37v-8.62h-9.09v8.38c0,.89.07,1.41.21,1.57.14.16.61.27,1.42.33.52.04.78.13.78.27,0,.18-.2.27-.6.27h-6.55c-.4,0-.6-.08-.6-.24s.2-.26.6-.3c.26-.02.53-.04.82-.06.44-.04.72-.14.85-.3.06-.1.09-.65.09-1.65v-15.6c0-.89-.05-1.41-.14-1.57-.09-.16-.41-.25-.95-.27-.62-.04-.99-.07-1.1-.09-.11-.02-.17-.1-.17-.25,0-.17.13-.25.39-.25.08,0,.25,0,.51.03.5.04,1.69.06,3.56.06,1.03,0,1.74-.01,2.14-.04.14-.03.32-.04.54-.04.36,0,.54.1.54.3,0,.18-.33.27-.98.27s-1.05.07-1.18.22c-.13.15-.2.62-.2,1.41v6.83h9.09v-6.38c0-1.05-.08-1.67-.24-1.86s-.72-.27-1.69-.25c-.3,0-.45-.09-.45-.27s.17-.27.51-.27h6.58c.42,0,.63.09.63.27s-.44.28-1.33.3c-.26,0-.45.02-.57.06-.2.06-.33.17-.38.33s-.07.62-.07,1.39v15.74c0,.95.05,1.5.15,1.66.2.22.77.33,1.73.33.32-.04.48.04.48.24Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M166.68,27.68c0,.2-.16.55-.47,1.05s-.63.91-.95,1.23c-.87.87-2.02,1.3-3.47,1.3-1.81,0-3.33-.64-4.54-1.92-1.22-1.28-1.83-2.87-1.83-4.76s.58-3.62,1.74-4.94c1.16-1.32,2.6-1.98,4.33-1.98,1.43,0,2.63.41,3.59,1.23.97.82,1.48,1.87,1.54,3.15-.64.1-1.5.14-2.57.12h-2.11c-.14,0-1.35.03-3.62.09-.1.65-.15,1.27-.15,1.86,0,1.99.39,3.51,1.16,4.59.77,1.07,1.87,1.61,3.28,1.61.81,0,1.45-.18,1.93-.53s1.01-1.04,1.57-2.04c.08-.16.19-.24.33-.24.16,0,.24.07.24.21ZM164.06,21.64c-.08-2.37-.95-3.55-2.6-3.55s-2.65,1.18-3.05,3.55h5.64Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M179.75,29.57c0,.34-.36.69-1.07,1.05-.71.37-1.4.55-2.06.55-1.38,0-2.1-.7-2.16-2.1-.76,1.46-1.94,2.19-3.54,2.19-.86,0-1.57-.25-2.13-.76-.56-.5-.84-1.15-.84-1.94,0-1.62.99-2.9,2.96-3.85.68-.32,1.85-.63,3.53-.95v-.74c0-1.74-.09-2.88-.27-3.43-.32-1.01-1.05-1.51-2.17-1.51-.7,0-1.29.19-1.77.56-.47.37-.71.85-.71,1.42,0,.16.13.24.38.24s.48.12.68.35c.2.24.3.5.3.8,0,.87-.44,1.3-1.33,1.3-.46,0-.85-.15-1.15-.46-.3-.31-.45-.7-.45-1.17,0-.93.37-1.73,1.12-2.4.82-.73,1.97-1.1,3.44-1.1,2.21,0,3.57.59,4.08,1.78.18.45.27,1.35.27,2.69v5.53c0,.91,0,1.44.03,1.6.1.71.39,1.07.88,1.07.56,0,1.03-.2,1.39-.59.2-.22.35-.33.45-.33s.15.06.15.18ZM174.44,26.44v-2.16c-1.49.34-2.55.77-3.2,1.32-.64.54-.97,1.27-.97,2.18,0,.63.18,1.15.55,1.55.37.4.83.61,1.38.61.85,0,1.54-.47,2.05-1.42.12-.22.18-.91.18-2.07Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M184.95,30.7c0,.18-.13.27-.39.27h-.36s-.57-.02-1.6-.06h-.94c-.16,0-.97.01-2.41.04-.12,0-.24.01-.36.01-.3.02-.45-.06-.45-.25,0-.13.11-.19.33-.19h.78c.56,0,.85-.12.85-.36.02-.12.03-.89.03-2.31v-15.06c0-.65-.02-1.01-.06-1.07-.06-.16-.22-.24-.48-.24h-1.09c-.28,0-.42-.08-.42-.25,0-.15.25-.23.76-.25.97-.06,1.86-.28,2.69-.65l.63-.3.24-.09c.16.57.23,1.44.21,2.6v16.6c0,.59,0,.94.03,1.05.02.11.09.18.21.22.02.02.39.04,1.12.06.46,0,.69.07.69.21Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M193.7,29.16c0,.18-.25.49-.76.95-.87.77-1.89,1.15-3.08,1.15-1.11,0-1.94-.28-2.51-.83-.32-.32-.48-1.24-.48-2.78v-8.8c-.02-.2-.13-.31-.33-.32h-1.15c-.44,0-.66-.07-.66-.21s.11-.22.33-.24c1.56-.14,2.78-.9,3.66-2.28.12-.18.27-.27.45-.27.14,0,.21.07.21.21v.62c0,.83.04,1.36.12,1.6.18.04.48.06.91.06,1.11,0,1.84-.05,2.2-.15.08-.02.15-.03.21-.03.16,0,.24.09.24.26,0,.28-.82.43-2.45.43h-1.24v5.48c0,2.76.07,4.49.21,5.19.14.7.6,1.05,1.39,1.05.66,0,1.41-.36,2.23-1.09.08-.1.17-.15.27-.15.14,0,.21.05.21.15Z"
                                            fill="#000" stroke-width="0" />
                                        <path
                                            d="M206.8,30.73c0,.16-.17.24-.51.24h-5.49c-.26,0-.39-.08-.39-.24,0-.14.38-.21,1.15-.21.46,0,.73-.05.8-.16.07-.11.11-.48.11-1.11v-5.99c0-1.79-.08-2.9-.24-3.34-.34-.91-1.1-1.36-2.26-1.36-.97,0-1.75.42-2.35,1.27-.2.28-.3.89-.3,1.83v7.53c0,.53,0,.84.03.92.02.2.1.31.24.34.14.03.57.05,1.3.07.24,0,.36.08.36.24,0,.14-.1.21-.3.21h-2.69c-1.67-.02-2.6-.02-2.78,0h-.3c-.22,0-.33-.06-.33-.18,0-.14.11-.21.33-.21h.45l.45-.03c.46.02.7-.12.72-.41v-13.31c0-3.16-.05-4.87-.15-5.13-.12-.2-.61-.31-1.48-.33-.48,0-.72-.07-.72-.22,0-.13.05-.2.15-.22.1-.02.44-.04,1.03-.06,1.03-.02,2.01-.26,2.96-.71.08-.04.22-.09.42-.15.14.24.21,1,.21,2.28v7.25c.88-1.26,2.09-1.89,3.62-1.89,1.61,0,2.76.43,3.44,1.3.34.43.55,1.05.63,1.83.04.28.06.94.06,1.98v7.46c.02.2.54.3,1.57.3.18.04.27.11.27.21Z"
                                            fill="#000" stroke-width="0" />
                                    </svg>
                                    <span class="u-hiddenVisually">Baptist Health</span>
                                </div>
                                <div>
                                    <small>
                                        ©
                                        <span id="copyright">
                                            {{ currentYear }}
                                        </span>
                                        Baptist Health.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <!-- FooterSection End -->
    </div>
</template>

<script>
import NavSection from '@/components/NavSection.vue';
import QuestionSection from '@/components/QuestionSection.vue';
import WelcomeBarSection from '@/components/WelcomeBarSection.vue';
  import { ref } from 'vue';

  export default {
    name: 'BaptistHealth',
    components: {
        NavSection,
        QuestionSection,
        WelcomeBarSection,
    },
    data() {
      return {
        isHeaderVisible: true,
        isContentVisible: true,
        wrapperPadding: '0px' // Initialize with no padding
      };
    },
    beforeRouteUpdate(to, from, next) {
        this.isHeaderVisible = false;
        next();
    },
    methods: {
        adjustWrapperPadding(isBarVisible) {
            this.wrapperPadding = isBarVisible ? '50px' : '0px';
        },
        resetHeaderVisibility() {
            this.$router.push("/baptist").then(() => {
                this.isHeaderVisible = true;
            }).catch(error => {
                console.error(error);
            });
        },
        toggleExpand() {
            this.isContentVisible = !this.isContentVisible;
        }
    },
    setup() {
        const currentYear = ref(new Date().getFullYear());
        return { currentYear };
    },
    mounted() {
      console.log('Baptist Health mounted');
    }
  };
</script>