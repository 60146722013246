<template>
    <div class="accordion">
        <h3 class="accordionHeader" @click="toggleVisibility">
            <span>{{ title }}</span>
            <span class="plusIcon" v-if="isVisible">-</span>
            <span class="plusIcon" v-else>+</span>
        </h3>

        <div class="detail" v-if="isVisible">
            <slot></slot> <!-- Use slot for content to make it flexible -->
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
    },
    data() {
        return {
            isVisible: false,
        };
    },
    methods: {
        toggleVisibility() {
            this.isVisible = !this.isVisible;
        },
    },
};
</script>
