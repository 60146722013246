<template>
  <section class="Band-content u-bgWhite u-padTop8 u-textBlack" id="signature">
    <div class="u-padSides8">
      <div class="u-containSpread">
        <h1 class="section-header u-textBrandAlt">Email Signature Creator</h1>
        <p class="u-sm-padTop4">
          Signature blocks at the end of emails should be brief and to the point. More than four lines long
          takes up too much of the screen, requiring the recipient to scroll down to read it.
        </p><br />
        <p>
          Create your own email signature right here by filling out the form. Your signature will be created
          for you so all you have to do is click the resulting copy button and paste in your email client. The
          signature will be formatted correctly for you.
        </p>

        <form @submit.prevent="onSubmit">
          <div class="Grid form-group u-spaceItems04">
            <div class="Grid Grid--withGap u-padTop8">
              <div class="Grid-cell u-size1of2">
                <label for="fullname" class="u-textBlackAlt">Full Name<span
                    class="u-hiddenVisually">(required)</span><sup class="u-textRed">*</sup></label>
                <Field class="required u-sizeFull" id="fullname" name="fullname" type="text" minlength="2"
                  maxlength="128" autocomplete="given-name" required as="input" />
                <ErrorMessage name="fullname" class="error-message" />
              </div>
              <div class="Grid-cell u-size1of2">
                <label for="title" class="u-textBlackAlt">Title<span class="u-hiddenVisually">(required)</span><sup
                    class="u-textRed">*</sup></label>
                <Field class="u-sizeFull" name="title" id="title" type="organization-title" maxlength="128"
                  minlength="2" autocomplete="organization-title" required as="input" />
                <ErrorMessage name="title" class="error-message" />
              </div>
              <div class="Grid-cell u-size1of2">
                <label for="hospital" class="u-textBlackAlt">Hospital</label>
                <Field class="dropdown u-sizeFull" name="hospital" id="hospital" as="select"
                  v-model="formValues.hospital">
                  <option selected value="-">-</option>
                  <option value="Baptist Health">Baptist Health</option>
                  <option value="Wolfson Children's Hospital">Wolfson Children's Hospital</option>
                  <option value="Baptist MD Anderson">Baptist MD Anderson</option>
                </Field>
                <ErrorMessage name="hospital" class="error-message" />
              </div>
              <div class="Grid-cell u-size1of2">
                <label for="dept" class="u-textBlackAlt">Department<span class="u-hiddenVisually">(required)</span><sup
                    class="u-textRed">*</sup></label>
                <Field class="required u-sizeFull" id="dept" name="dept" type="text" minlength="2" maxlength="128"
                  autocomplete="dept" required as="input" />
                <ErrorMessage name="dept" class="error-message" />
              </div>
              <div class="Grid-cell u-size1of2">
                <label for="email" class="u-textBlackAlt">Email Address <span
                    class="u-hiddenVisually">(required)</span><sup class="u-textRed">*</sup></label>
                <Field class="u-sizeFull" name="email" id="email" type="email" maxlength="128" minlength="2"
                  autocomplete="email" required as="input" />
                <ErrorMessage name="email" class="error-message" />
              </div>
              <div class="Grid-cell u-size1of2">
                <label for="phone" class="u-textBlackAlt">Phone</label>
                <Field class="u-sizeFull" name="phone" id="phone" type="phone" maxlength="128" minlength="2"
                  autocomplete="tel" v-phone-format as="input" />
                <ErrorMessage name="phone" class="error-message" />
              </div>
              <div class="Grid-cell u-sizeFull">
                <div class="">
                  <button type="submit"
                    class="Button Button--tertiary u-textSize1 u-textCenter u-sizeFull u-borderColorBlackAlt u-textBlackAlt u-textUpper">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="Grid Grid--withGap editable-content" v-if="resultHtml" v-html="resultHtml" id="resultDiv"
          ref="content" contenteditable="true"></div>

        <div class="Grid Grid--withGap u-padTop3" v-if="sigCreated" id="sigButtons">
          <div class="Grid-cell u-size1of2">
            <button type="button" @click="copySig"
              class="Button Button--tertiary u-textSize1 u-textCenter u-sizeFull u-borderColorBlackAlt u-textBlackAlt u-textUpper">Copy
              Signature</button>
          </div>
          <div class="Grid-cell u-size1of2">
            <button type="button" @click="resetSig"
              class="Button Button--tertiary u-textSize1 u-textCenter u-sizeFull u-borderColorBlackAlt u-textBlackAlt u-textUpper">Create
              Another Signature</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue';
import { useForm, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

// Custom directive for phone number formatting
const phoneFormat = {
  beforeMount(el) {
    el.addEventListener('input', function (e) {
      let value = e.target.value.replace(/\D/g, '');
      if (value.length > 3 && value.length <= 6) {
        value = value.replace(/(\d{3})(\d+)/, '$1-$2');
      } else if (value.length > 6) {
        value = value.replace(/(\d{3})(\d{3})(\d+)/, '$1-$2-$3');
      }
      e.target.value = value;
    });
  }
};

const app = getCurrentInstance();
if (app && app.appContext.app) {
  app.appContext.app.directive('phone-format', phoneFormat);
}

const content = ref(null);
const resultHtml = ref('');
const sigCreated = ref(false);

const formValues = ref({
  hospital: '-',
});

const { handleSubmit } = useForm({
  validationSchema: yup.object({
    fullname: yup.string().required('Full Name is required').min(2, 'Full Name must be at least 2 characters'),
    title: yup.string().required('Title is required').min(2, 'Title must be at least 2 characters'),
    hospital: yup.string()
      .notOneOf(['-'], 'Hospital selection is required')
      .required('Hospital selection is required'),
    dept: yup.string().required('Department is required').min(2, 'Department must be at least 2 characters'),
    email: yup.string().required('Email is required').email('Email must be a valid email address'),
    phone: yup.string().nullable(),
  }),
  initialValues: formValues.value,
});

const onSubmit = handleSubmit(values => {
  resultHtml.value = `
    <div class='Grid-cell u-sizeFull'>
      <p>${values.fullname} | 
      <em>${values.title}</em>, 
      ${values.dept} | 
      <strong>${values.hospital}</strong><br/>
      ${values.phone ? values.phone + ' | ' : ''}<a href='mailto:${values.email}'>${values.email}</a></p>
    </div>`;
  sigCreated.value = true;
});

function resetSig() {
  if (content.value) {
    resultHtml.value = '';
    sigCreated.value = false;
    content.value.innerHTML = ''; // Clear the content
    // formValues.value.hospital = '-'; // Reset hospital to default value
  }
}

function copySig() {
  if (!content.value) return;

  // Create a temporary element that will contain the HTML content
  const tempEl = document.createElement('div');
  tempEl.style.position = 'absolute';
  tempEl.style.left = '-9999px';
  tempEl.innerHTML = content.value.innerHTML;
  document.body.appendChild(tempEl);

  // Use the Selection API to select the content
  const selection = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(tempEl);
  selection.removeAllRanges();
  selection.addRange(range);

  try {
    // Execute copy command
    const successful = document.execCommand('copy');
    const msg = successful ? 'CMD + V (Mac) or CTRL + V (Windows) to paste.' : 'Failed to copy content.';
    alert(msg);
  } catch (err) {
    console.error('Failed to copy:', err);
  }

  // Clean up
  document.body.removeChild(tempEl);
  selection.removeAllRanges();
}
</script>
