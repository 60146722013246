<template>
    <section class="Band-content u-bgWhite u-padTop8 u-textBlack" id="color">
        <div class="u-padSides8">
            <div class="u-containSpread">
                <HeaderSection :sectionTitle="sectionTitle" :headerColor="headerColor" />
                <p>
                    Color helps set the brand apart. There is a primary color scheme and supporting secondary hues.
                </p>
                <h2 class="u-textBlackAlt u-padTop2">Brand Core</h2>
                <p>
                    Baptist Health blue is the signature color for the Baptist Health cross and is also utilized for
                    headlines and accent text, such as sidebars, in our layouts. Two accent colors, a lighter blue and a
                    green, are used sparingly to add visual interest to images, headlines, or sidebars while not
                    overwhelming the design.
                </p><br />
                <div class="Grid Grid--withGap">
                    <div class="Grid-cell u-sm-sizefull u-lg-size2of3">
                        <div class="swatch-lg u-bgBrand u-textWhite u-pad2 u-sm-pad1 u-md-pad8">
                            <h3>Brand Blue</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#0055A5</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">91-71-1-0</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">40-91-168</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3">
                        <div class="swatch-lg u-bgLtBlue u-textBlackAlt u-pad2 u-sm-pad1 u-md-pad8">
                            <h3>Blue</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#9ECEEB</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">33-3-0-3</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">164-215-244</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3">
                        <div class="swatch-lg u-bgCoolGray u-textWhite u-pad2 u-sm-pad1 u-md-pad8">
                            <h3>Slate</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#4E4F53</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">2-2-0-67</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">78-79-83</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3">
                        <div class="swatch-lg u-bgCoolGray4 u-textBlackAlt u-pad2 u-sm-pad1 u-md-pad8">
                            <h3>Silver</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#D6D6D4</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">15-11-13-0</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">214-215-212</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3">
                        <div class="swatch-lg u-bgBHLtGreen u-textWhite u-pad2 u-sm-pad1 u-md-pad8">
                            <h3>Green</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size2of8">#79BC43</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">58-2-100-0</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">121-188-67</div>
                            </div>
                        </div>
                    </div>
                </div>

                <h2 class="u-textBlackAlt u-padTop8">Warm</h2>
                <p>
                    Dynamic and energetic, these secondary colors can evokes feelings of warmth and comfort.
                </p><br />
                <div class="Grid Grid--withGap">
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of4">
                        <div class="swatch-sm u-bgRed u-textWhite u-pad2 u-sm-pad1 u-md-pad2">
                            <h3>Red</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#D12323</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">67-59-53-34</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">79-79-83</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of4">
                        <div class="swatch-sm u-bgOrangeAlt u-textWhite u-pad2 u-sm-pad1 u-md-pad2">
                            <h3>Orange Alternative</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#E65709</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">33-3-0-0</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">164-215-244</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of4">
                        <div class="swatch-sm u-bgOrange u-textWhite u-pad2 u-sm-pad1 u-md-pad2">
                            <h3>Orange</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#F27026</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">15-11-13-0</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">214-215-212</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of4">
                        <div class="swatch-sm u-bgLime u-textBlackAlt u-pad2 u-sm-pad1 u-md-pad2">
                            <h3>Lime</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#C1D72E</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">29-0-100-0</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">193-216-47</div>
                            </div>
                        </div>
                    </div>
                </div>

                <h2 class="u-textBlackAlt u-padTop8">Cool</h2>
                <p>
                    Calming and peaceful, these secondary colors can convey trustworthiness and reliability.
                </p><br />
                <div class="Grid Grid--withGap">
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of4">
                        <div class="swatch-sm u-bgBrand u-textWhite u-pad2 u-sm-pad1 u-md-pad2">
                            <h3>Brand Blue</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#0055A5</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">91-71-1-0</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">40-91-168</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of4">
                        <div class="swatch-sm u-bgBlue u-textWhite u-pad2 u-sm-pad1 u-md-pad2">
                            <h3>Blue</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#389BDA</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">64-25-0-15</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">56-155-218</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of4">
                        <div class="swatch-sm u-bgTealAlt u-textWhite u-pad2 u-sm-pad1 u-md-pad2">
                            <h3>Teal</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#00748C</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">100-17-0-45</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">0-116-140</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of4">
                        <div class="swatch-sm u-bgBHGreen u-textWhite u-pad2 u-sm-pad1 u-md-pad2">
                            <h3>Green</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#057A5F</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">46-0-11-52</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">5-122-95</div>
                            </div>
                        </div>
                    </div>

                </div>

                <h2 class="u-textBlackAlt u-padTop8">Neutral</h2>
                <p>
                    Versatile and simple, these colors are often used as background or foundational hues to support the
                    brand colors.
                </p><br />
                <div class="Grid Grid--withGap">
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3">
                        <div class="swatch-sm u-bgBlackAlt u-textWhite u-pad2 u-sm-pad1 u-md-pad2">
                            <h3>Black Alternative</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#48453f</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">0-1-4-72</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">72-69-63</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3">
                        <div class="swatch-sm u-bgCoolGray u-textWhite u-pad2 u-sm-pad1 u-md-pad2">
                            <h3>Slate</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#4E4F53</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">2-2-0-67</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">78-79-83</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of3">
                        <div class="swatch-sm u-bgCoolGray4 u-textBlackAlt u-sm-pad1 u-md-pad2">
                            <h3>Silver</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#D6D6D4</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">15-11-13-0</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">RGB</div>
                                <div class="Grid-cell u-size6of8">214-215-212</div>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 class="u-textBlackAlt u-padTop8">Gradients</h2>
                <p>
                    Although used sparingly, these gradient compositions serve as accent elements.
                </p><br />
                <div class="Grid Grid--withGap">
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of2">
                        <div class="swatch-grad u-bgGradientGrBl u-textBlackAlt u-pad1">
                            <h3>Green Blue</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#B5FF00 to #68F5F5</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">29-0-100-0 to 58-0-0-4</div>
                            </div>
                        </div>
                    </div>
                    <div class="Grid-cell u-sm-sizefull u-lg-size1of2">
                        <div class="swatch-grad u-bgGradientBlBl u-textWhite u-pad1">
                            <h3>Blue Blue</h3>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">HEX</div>
                                <div class="Grid-cell u-size6of8">#024CF2 to #64F0F0</div>
                            </div>
                            <div class="Grid Grid--withGap">
                                <div class="Grid-cell u-size2of8">CMYK</div>
                                <div class="Grid-cell u-size6of8">99-69-0-5 to 59-2-2-4</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';
export default {
    name: 'BaptistHealth',
    components: {
        HeaderSection,
    },
    data() {
        return {
            sectionTitle: "Color",
            headerColor: "u-textBrandAlt",
        };
    },
    mounted() {
        // console.log('Color component mounted');
    }
};
</script>