<template>
    <h1 :class="['section-header', headerColor]">{{ sectionTitle }}</h1>
</template>

<script>
export default {
    name: 'HeaderSection',
    props: {
        headerColor: String,
        sectionTitle: String
    }
}
</script>