<template>
    <div v-if="showWelcomeBar" class="welcome-bar">
        <div class="Grid Grid--withGap">
            <div class="Grid-cell u-md-size2of3 u-md-textRight u-sm-textCenter">
                <p>{{ message }}</p>
            </div>
            <div class="Grid-cell u-md-size1of3 u-md-textLeft u-sm-textCenter">
                <button class="Button Button--primary" @click="closeBar">{{
                    callToAction }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showWelcomeBar: true,
            message: "Our design principles, voice, and visual identity are essential for maintaining brand integrity.",
            callToAction: "Explore Now"
        };
    },
    mounted() {
        if (localStorage.getItem("welcomeBarClosed")) {
            this.showWelcomeBar = false;
        }
        this.$emit('toggleWelcomeBar', this.showWelcomeBar);
    },
    methods: {
        closeBar() {
            this.showWelcomeBar = false;
            localStorage.setItem("welcomeBarClosed", "true");
            this.$emit('toggleWelcomeBar', this.showWelcomeBar);
        },
        handleClick() {
            console.log("Call to action clicked");
        }
    }
};
</script>

<style scoped>
.welcome-bar {
    position: relative;
    /* changed from fixed to relative */
    width: 100%;
    background-color: #efefef;
    color: #000;
    padding: 10px;
    text-align: center;
    z-index: 1;
    height: auto;
    p {
        line-height: 1.5;
        margin-bottom: 0;
        margin-top: 1rem;
        padding: 0 2rem;
    }
}

.close-button {
    position: absolute;
    right: 10px;
    top: 5px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
}
</style>
