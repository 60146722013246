import { createRouter, createWebHistory } from "vue-router";
import BaptistHealth from "./views/Baptist/BaptistHealth.vue";
import WolfsonChildrens from "./views/Wolfson/WolfsonChildrens.vue";
import BMDA from "./views/BMDA/BMDA.vue";


import BaptistVoice from "./views/Baptist/VoicePage.vue";
import BaptistStructure from "./views/Baptist/StructurePage.vue";
import BaptistLogos from "./views/Baptist/LogosPage.vue";
import BaptistColor from "./views/Baptist/ColorPage.vue";
import BaptistTypography from "./views/Baptist/TypographyPage.vue";
import BaptistIcons from "./views/Baptist/IconsPage.vue";
import BaptistSignatureEmail from "./views/Baptist/EmailSignaturePage.vue";

import WolfsonVoice from "./views/Wolfson/VoicePage.vue";
import WolfsonColor from "./views/Wolfson/ColorPage.vue";
import WolfsonLogos from "./views/Wolfson/LogosPage.vue";
import WolfsonTypography from "./views/Wolfson/TypographyPage.vue";
import WolfsonIcons from "./views/Wolfson/IconsPage.vue";
import WolfsonStructure from "./views/Wolfson/StructurePage.vue";
import WolfsonSignatureEmail from "./views/Baptist/EmailSignaturePage.vue";

import BMDAVoice from "./views/BMDA/VoicePage.vue";
import BMDAColor from "./views/BMDA/ColorPage.vue";
import BMDALogos from "./views/BMDA/LogosPage.vue";
import BMDATypography from "./views/BMDA/TypographyPage.vue";
import BMDAIcons from "./views/BMDA/IconsPage.vue";
import BMDAStructure from "./views/BMDA/StructurePage.vue";
import BMDASignatureEmail from "./views/Baptist/EmailSignaturePage.vue";

import NotFound from "./views/NotFound.vue";


const routes = [
  {
    path: "/",
    component: BaptistHealth,
  },
  {
    path: "/Baptist",
    component: BaptistHealth,
    meta: { title: "Baptist Health Brand Guide" },
    children: [
      { path: "voice", component: BaptistVoice },
      { path: "structure", component: BaptistStructure },
      { path: "color", component: BaptistColor },
      { path: "logos", component: BaptistLogos },
      { path: "typography", component: BaptistTypography },
      { path: "icons", component: BaptistIcons },
      { path: "emailsignature", component: BaptistSignatureEmail },
    ],
  },
  {
    path: "/Wolfson",
    component: WolfsonChildrens,
    meta: { title: "Wolfson Children's Brand Guide" },
    children: [
      { path: "voice", component: WolfsonVoice },
      { path: "structure", component: WolfsonStructure },
      { path: "color", component: WolfsonColor },
      { path: "logos", component: WolfsonLogos },
      { path: "typography", component: WolfsonTypography },
      { path: "icons", component: WolfsonIcons },
      { path: "emailsignature", component: WolfsonSignatureEmail },
    ],
  },
  {
    path: "/BMDA",
    component: BMDA,
    meta: { title: "Baptist MD Anderson Brand Guide" },
    children: [
      { path: "voice", component: BMDAVoice },
      { path: "structure", component: BMDAStructure },
      { path: "color", component: BMDAColor },
      { path: "logos", component: BMDALogos },
      { path: "typography", component: BMDATypography },
      { path: "icons", component: BMDAIcons },
      { path: "emailsignature", component: BMDASignatureEmail },
    ],
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Baptist Health Brand Guide";
  next();
});

export default router;
