<template>
    <section class="Band-content u-bgWhite u-padTop8 u-textBlack" id="voice">
        <div class="u-padSides8">
            <div class="u-containSpread">
                <h1 :class="['section-header', headerColor]">{{ sectionTitle }}</h1>
                <div>
                    <h3 v-for="(para, index) in paragraph" :key="index" class="intro">
                        {{ para }}
                    </h3>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'VoiceSection',
    props: {
        headerColor: String,
        sectionTitle: String,
        paragraph: {
            type: Array,
            default: () => []
        }
    }
};
</script>

