<template>
    <section class="Band-content u-bgWhite u-padTop8 u-textBlack">
        <div class="u-padSides8">
            <div class="u-containSpread">
                <div id="structure">
                    <div class="Grid Grid--withGap">
                        <HeaderSection
                            :sectionTitle="sectionTitle"
                            :headerColor="headerColor" />
                        <div class="Grid-cell u-sizefull">
                            <div class="Grid Grid--withGap">
                                <h3>Vision</h3>
                                <p>
                                    A lifetime of health together.
                                </p>
                                <h3>Mission</h3>
                                <p>
                                    To make hope, healing and well-being accessible to every person as an
                                    expression of God's love.
                                </p>
                            </div>
                        </div>
                        <div class="Grid-cell u-sizefull">
                            <div class="Grid Grid--withGap">
                                <h3>Guiding Principles</h3>
                                <ul class="u-padLeft8">
                                    <li>We are guided by the healing ministry of Christ.</li>
                                    <li>We believe in the dignity of every person.</li>
                                    <li>We promote physical, mental and spiritual well-being.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="Grid-cell u-sizefull u-padTop4">
                            <div class="Grid Grid--withGap">
                                <h3>Key brand attributes</h3>
                                <ul class="u-padLeft8">
                                    <li>Honest, genuine, accessible</li>
                                    <li>Empathetic, with you, for you</li>
                                    <li>Family</li>
                                    <li>Friendly, life-long relationship</li>
                                    <li>Integrity, highest standards, committed</li>
                                    <li>Innovative, energetic leader</li>
                                    <li>Community</li>
                                </ul>
                            </div>
                        </div>

                        <hr />
                        <div class="Grid-cell u-sizefull">
                            <div class="">
                                <div class="Grid Grid--withGap">
                                    <h3>Brand Values</h3>
                                    <div class="Grid-cell u-sm-sizefull u-lg-size2of4">
                                        <div class="">
                                            <div class="Grid Grid--withGap">
                                                <p>
                                                    <strong>Community Advocacy</strong> - We are dedicated to enhancing
                                                    the well-being of every member of our community. By prioritizing
                                                    accessibility and forming strong partnerships, we engage actively in
                                                    civic life. We strive to advance health equity through responsible
                                                    actions and charitable services, ensuring everyone has the support
                                                    they need to thrive.
                                                </p>
                                                <p>
                                                    <strong>Respect</strong> - We cultivate a culture of warmth and
                                                    inclusiveness where everyone feels they belong. We honor the dignity
                                                    of those we serve and each other, approaching every interaction with
                                                    compassion, integrity, and deep respect.
                                                </p>
                                                <p>
                                                    <strong>Excellence</strong> - We commit to the highest quality of
                                                    care and service in everything we do. We aim to exceed expectations
                                                    through continuous improvement and innovation.
                                                </p>
                                                <p>
                                                    <strong>Stewardship</strong> - We prioritize efficient, responsible,
                                                    and equitable management of resources. Our commitment to stewardship
                                                    ensures our actions benefit those we serve and the community.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';

export default {
    name: 'BMDA',
    components: {
        HeaderSection
    },
    data() {
        return {
            sectionTitle: "Brand Structure",
            headerColor: "u-textBMDA",
        };
    },
    mounted() {
        console.log('Structure component mounted');
    },
};
</script>
