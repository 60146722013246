<template>
    <VoiceSection
        :sectionTitle="sectionTitle"
        :headerColor="headerColor"
        :paragraph="paragraphs" />
</template>

<script>
import VoiceSection from '@/components/VoiceSection.vue';

export default {
    name: 'WolfsonChildrens',
    components: {
        VoiceSection
    },
    data() {
        return {
            sectionTitle: "Voice",
            headerColor:"u-textBlue",
            paragraphs: [
                "Our writing style is modern and sincere, like a conversation between good friends. It's approachable and transparent, which is crucial in healthcare communication.",
                "When the topic allows, subtle humor and irony can make the information more relatable and digestible, reflecting healthcare's natural, human side. Just as we value authenticity and a hint of playful imperfection in our visuals, we aim to reflect those qualities in our words, too. After all, we're not just discussing medical conditions - we're talking about life."
            ]
        };
    }
};
</script>
